<script>
import Layout from "../../layouts/horizontal-absolute";
import { parseErrors } from '../../../helpers'
import Swal from "sweetalert2";
import http from '@/oauth-client'
import moment from 'moment'
import FilterCard from "@/views/pages/indices/filter-cardv2";
import simplebar from "simplebar-vue";
import { uuid } from 'vue-uuid';
import { mapState } from 'vuex';
import { assetClasses } from '@/helpers'
import Multiselect from "vue-multiselect";

import {
   authComputed
} from "@/state/helpers";

export default {
  components: {
    Layout,
    FilterCard,
    simplebar,
     Multiselect
  },
  data() {
    
    return {     
      inputParamtersFormId: null,
      inputParametersFormOutsideClickConfig: {
        handler: this.hideInputParamtersForm,
        events: ["click"],
      },
      subscriptions: {}, 
      showOnlyBookmarks: false,
      fieldsMap:{
        'name': this.$t('tables.indices.columns.name'),
        'index-type': this.$t('tables.indices.columns.indexType'),
        'market': this.$t('tables.indices.columns.market'),
        'provider':this.$t('tables.indices.columns.provider'),
        'asset-class': this.$t('tables.indices.columns.assetClass'),
        'instrument-type': this.$t('tables.indices.columns.instrumentType'),
        'rebalance-frequency': this.$t('tables.indices.columns.rebalanceFrequency'),
        'ytd': this.$t('tables.indices.columns.ytd'),
        'mtd': this.$t('tables.indices.columns.mtd'),
        'annualised-return': this.$t('tables.indices.columns.annualisedReturn'),
        'realised-volatility': this.$t('tables.indices.columns.realisedVolatility'),
        'downside-volatility': this.$t('tables.indices.columns.downsideVolatility'),
        'max-drawdown': this.$t('tables.indices.columns.maxDrawdown'),
        'winning-months': this.$t('tables.indices.columns.winningMonths'),
        'sharpe-ratio': this.$t('tables.indices.columns.sharpeRatio'),
        'sortino-ratio': this.$t('tables.indices.columns.sortinoRatio'),
        'calmar-ratio': this.$t('tables.indices.columns.calmarRatio'),
        'external-id': this.$t('tables.indices.columns.externalId')
      },
      mainChartYAxisLogarithmicType: false,

      filtersData: null,
      filterCriteria: null,

      bookmarks:{},
      indexViewHistory:[],
    
      items:[],
      errors:[],

      initializing: true,

      composedIndexSubmiting: false,
      screeningSubmiting: false,

      viewMode: 'grid',
      composedIndexBasicInfo: {
        name: '',
        description: '',
        assetClass: '',
        benchMark: '',
        isTemplate: false,
        tags: []
      },
      assetClasses: assetClasses,
      allTags: [],
      screening: this.createScreeningData(),

      composeIndexEnabled: false,
      composeIndexStep: 0,

      composeIndexItems: [],
      selectedPortfolioSolution: null,
      publicIndex: false,
      optimization: {
        walkForwardOptimizationEnabled: false,
        walkForwardOptimization: {
          inSamplePeriod:0,
          periodBetweenRebalancing: 0
        },
        targetVolatilityEnabled: false,
        targetVolatility: 0,
        startDate: null,
        endDate: null,
        solution:{
          values:{

          }
        }
      },

      statColumns: {
        market:{
          visible: true
        },
        provider:{
          visible: true
        },
        indexType:{
          visible: true
        },
        indexSubType:{
          visible: true
        },
        statUpdatedAt:{
          visible: true
        },
        instrumentType:{
          visible: true
        },
        assetClass:{
          visible: true
        },
        rebalanceFrequency:{
          visible: true
        },
        mtd:{
          visible: true
        },
        ytd:{
          visible: true
        },

        annualisedReturn:{
          visible: true
        },
        realisedVolatility:{
          visible: true
        },
        downsideVolatility:{
          visible: true
        },
        maxDrawdown:{
          visible: true
        },
        winningMonths:{
          visible: true
        },
        sharpeRatio:{
          visible: true
        },
        sortinoRatio:{
          visible: true
        },
        calmarRatio:{
          visible: true
        },
        externalId:{
          visible: false
        },
      },
    

      dictionary:{
        rebalanceFrequencyMap:{

        },
        assetClassMap:{

        },
        instrumentTypeMap: {

        },
      },

      filters:[

      ],
      isBusy: false,

      keysLoading: false,
   
      keys:[],
  
      imageUrl: null,
      imageVisible: false,

      criteria: this.defaultCriteria(),

      productQuery: null,
      productsLoading: false,
      products:[],

      portfolioSolutions: [],
     
      currentPage: 1,
      perPage: 20,
      totalItems: 0,
      breadcrumbs: [
        {
          text: 'Marketplace',
          active: true
        }
      ],

      uploadVisible: false,
      uploadProcessing: false,
      selectedItemId: null,

      equityMetaData: [],
      equityMetaDataCheckBoxes: {}
    };
  },
  created() {
    this.loadSubscriptions();

    this.filters.push({
      nameAll: 'Select All',
      nameNotAll: 'Reset',
        func(elem) {
          return elem.selected;
        }
    });
    
    this.init();

  },
  watch: {
    connectionId: {
      handler: function () {
        this.loadSubscriptions();
      }
    },
    mainChartYAxisLogarithmicType:{
      handler: function () {
        if(this.initializing === true) return;
        this.rebuildNetAssetValueGrowthCharts();
        this.saveUserData();
      }
    },
    viewMode:{
      handler: function () {
        if(this.initializing === true) return;
        this.saveUserData();
      }
    },
    statColumns: {
      deep: true,
      handler: function () {
        if(this.initializing === true) return;
        this.saveUserData();
      }
    },
    composedIndexBasicInfo:{
      deep: true,
      handler: function () {
        if(this.initializing === true) return;
        this.saveUserData();
      }
    },
    screening: {
      deep: true,
      handler: function () {
        if(this.initializing === true) return;
        this.saveUserData();
      }
    },
    optimization:{
      deep: true,
      handler: function () {
        if(this.initializing === true) return;

        if(this.optimization) {
          if(this.optimization.targetVolatility === 0 && this.optimization.targetVolatilityEnabled) {
            this.optimization.targetVolatility = 10;
          }
        }
       
        this.saveUserData();
      }
    },
    showOnlyBookmarks:{
      handler: function () {
        if(this.initializing === true) return;
      
        this.saveUserData();
        this.applyFilters();
      }
    },
   'criteria.normalisedPerformance': {
      handler: function () {
        if(this.initializing === true) return;
        this.saveUserData();
        this.load();
      }
    },
    selectedPortfolioSolution: {
        deep: true,
        handler: function () {
          if(this.initializing === true) return;
          this.saveUserData();
        }
    },
    currentPage: {
      handler: function () {
        if(this.initializing === true) return;
        this.saveUserData();
        this.load();
      }
    },
    perPage: {
       handler: function () {
        if(this.initializing === true) return;
        this.saveUserData();
        this.load();
      }
    },
    assetClassSolutionParameters(newVal) {
      const assetClassItem = this.equityMetaData.find(item => item.name === 'assetClass');
      if (assetClassItem) {
        assetClassItem.solutionParameters = newVal;
      }
    },
    regionSolutionParameters(newVal) {
      const regionItem = this.equityMetaData.find(item => item.name === 'region');
      if (regionItem) {
        regionItem.solutionParameters = newVal;
      }
    },
    fundamentalsSolutionParameters(newVal) {
      const fundamentalsItem = this.equityMetaData.find(item => item.name === 'fundamentals');
      if (fundamentalsItem) {
        fundamentalsItem.solutionParameters = newVal;
      }
    },
    sectorSolutionParameters(newVal) {
      const sectorItem = this.equityMetaData.find(item => item.name === 'sector');
      if (sectorItem) {
        sectorItem.solutionParameters = newVal;
      }
    },
    equityMetaDataCheckBoxes: {
      deep: true,
      handler() {
        for (const subClass of this.equityMetaData) {
          this.calculateSubClassCheckbox(subClass);
        }
      }
    }
  },
  computed: {
    ...authComputed,
    ...mapState('signalr', {
      connectionId: 'connectionId'
    }),
    filteredItems() {
      return this.showOnlyBookmarks 
        ? this.items.filter(x => this.bookmarks[x.id])
        : this.items;
    },
    totalPages() {
      return Math.ceil(this.totalItems / this.perPage);
    },
    isAdmin() {
      return this.currentUser.isAdmin;
    },
    checkedEditedComposition: {
        get() {
        return this.composeIndexItems.find(x => x.checked !== true) ? false : true;
      },
      set(v) {
        if(v) {
          this.composeIndexItems.forEach(x => x.checked = true);
        } else {
          this.composeIndexItems.forEach(x => x.checked = false);
        }
        this.$forceUpdate();
      }
    },
    title (){
      if(this.composeIndexEnabled) {
        return this.$t("pages.indices.composeIndexTitle");
      }else if(this.screening.enabled) {
        return this.$t("pages.indices.screeningTitle");
      } else {
        return 'Marketplace';
      }
    },
    isFilterEnabled() {
      if(this.isStatFiltered) return true;
      if(this.criteria.composedIndex) return true;
      if((this.criteria.portfolioSolution || []).length > 0) return true;
      if(this.criteria.assetClass.length > 0) return true;
      if(this.criteria.instrumentType.length > 0) return true;
      if(this.criteria.indexType.length > 0) return true;
      if(this.criteria.rebalanceFrequency.length > 0) return true;
      if(this.criteria.market.length > 0) return true;
      if(this.criteria.keyword) return true;
      if(this.criteria.provider.length > 0) return true;

      return false;
    },
    isStatFiltered() {
      if(this.criteria.mtd.from !== null && this.criteria.mtd.from !== undefined) return true;
      if(this.criteria.mtd.till !== null && this.criteria.mtd.till !== undefined) return true;
      
      if(this.criteria.ytd.from !== null && this.criteria.ytd.from !== undefined) return true;
      if(this.criteria.ytd.till !== null && this.criteria.ytd.till !== undefined) return true;

      if(this.criteria.annualisedReturn.from !== null && this.criteria.annualisedReturn.from !== undefined) return true;
      if(this.criteria.annualisedReturn.till !== null && this.criteria.annualisedReturn.till !== undefined) return true;

      if(this.criteria.realisedVolatility.from !== null && this.criteria.realisedVolatility.from !== undefined) return true;
      if(this.criteria.realisedVolatility.till !== null && this.criteria.realisedVolatility.till !== undefined) return true;

      if(this.criteria.downsideVolatility.from !== null && this.criteria.downsideVolatility.from !== undefined) return true;
      if(this.criteria.downsideVolatility.till !== null && this.criteria.downsideVolatility.till !== undefined) return true;
      
      if(this.criteria.maxDrawdown.from !== null && this.criteria.maxDrawdown.from !== undefined) return true;
      if(this.criteria.maxDrawdown.till !== null && this.criteria.maxDrawdown.till !== undefined) return true;

      if(this.criteria.winningMonths.from !== null && this.criteria.winningMonths.from !== undefined) return true;
      if(this.criteria.winningMonths.till !== null && this.criteria.winningMonths.till !== undefined) return true;

      if(this.criteria.sharpeRatio.from !== null && this.criteria.sharpeRatio.from !== undefined) return true;
      if(this.criteria.sharpeRatio.till !== null && this.criteria.sharpeRatio.till !== undefined) return true;

      if(this.criteria.sortinoRatio.from !== null && this.criteria.sortinoRatio.from !== undefined) return true;
      if(this.criteria.sortinoRatio.till !== null && this.criteria.sortinoRatio.till !== undefined) return true;

      if(this.criteria.calmarRatio.from !== null && this.criteria.calmarRatio.from !== undefined) return true;
      if(this.criteria.calmarRatio.till !== null && this.criteria.calmarRatio.till !== undefined) return true;

      return false;
    },
    assetClassSolutionParameters() {
      if(this.selectedPortfolioSolution) {
        return this.selectedPortfolioSolution.solutionParameters.filter(x => x.name.startsWith('AssetClass'));
      } else {
        return [];
      }
    },
    regionSolutionParameters() {
      if(this.selectedPortfolioSolution) {
        return this.selectedPortfolioSolution.solutionParameters.filter(x => x.name.startsWith('Region'));
      } else {
        return [];
      }
    },
    fundamentalsSolutionParameters() {
      if(this.selectedPortfolioSolution) {
        return this.selectedPortfolioSolution.solutionParameters.filter(x => x.name.startsWith('Fundamentals'));
      } else {
        return [];
      }
    },
    sectorSolutionParameters() {
      if(this.selectedPortfolioSolution) {
        return this.selectedPortfolioSolution.solutionParameters.filter(x => x.name.startsWith('SectorL1'));
      } else {
        return [];
      }
    },
    solutionParametersNoEquityMetaData() {
      if(this.selectedPortfolioSolution) {
        return this.selectedPortfolioSolution.solutionParameters.filter((param) =>
          param.scope === 'PerSolution' &&
          !this.assetClassSolutionParameters.includes(param) && !this.regionSolutionParameters.includes(param) &&
          !this.fundamentalsSolutionParameters.includes(param) && !this.sectorSolutionParameters.includes(param)
        ) || [];
      } else {
        return [];
      }
    }
  },
  methods: {
    toggleInputParamtersForm(id) {
      console.log('toggleInputParamtersForm', id);
      if(this.inputParamtersFormId === id) {
        this.inputParamtersFormId = null;
      } else {
        this.inputParamtersFormId = id;
      }
    },
    copyParameterToSelected(index, indexId, p) {
      const key = p.id + indexId;
      const v = this.optimization.solution.values[key];
      for(let i = 0; i < this.composeIndexItems.length; i++) {
        if(i != index && this.composeIndexItems[i].checked === true) {
          const childId = this.composeIndexItems[i].id;
          const newKey = p.id + childId; 
          this.optimization.solution.values[newKey] = v;
        }
      }
      this.$forceUpdate();
      this.hideInputParamtersForm();
    },
    copyParameterToAll(index, indexId, p) {
      const key = p.id + indexId;
      const v = this.optimization.solution.values[key];
      for(let i = 0; i < this.composeIndexItems.length; i++) {
        if(i != index) {
          const childId = this.composeIndexItems[i].id;
          const newKey = p.id + childId; 
          this.optimization.solution.values[newKey] = v;
        }
      }
      this.$forceUpdate();
      this.hideInputParamtersForm();
    },
    hideInputParamtersForm() {
      this.inputParamtersFormId = null;
    },
    async loadSubscriptions() {
      if(this.connectionId && !this.subscriptionsLoaded) {
        const req = { 
          id: uuid.v1(),
          clientId: this.$store.state.signalr.connectionId,
          type: 'LOAD_ALL_SUBSCRIPTIONS',
          payload: null,
          userName: this.$store.state.auth.currentUser.username,
          userId: this.$store.state.auth.currentUser.id,
        };
      
        try {
          const response = await http.post('api/backend/invoke', req);
          const payload = response.data.Payload;
        
          this.subscriptions = payload.Subscriptions.reduce((acc, id) => {
            acc[id] = true;
            return acc;
          }, {});
          console.log('loadSubscriptions response', {response, payload, subscriptions: this.subscriptions});
        } catch(e) {
          console.error(e);
        } 
        this.subscriptionsLoaded = true;
      }
    },
    parseDatePicker(v){
      const o = moment(v, 'YYYY-MM-DD');
      return o.format('DD/MM/YYYY');
    },
    onFilterApply (criteria) {
      this.filterCriteria = criteria;
      this.currentPage = 1;
      this.saveUserData();
      this.load();
    },
    async loadFilters() {
      const resp = await http.get( 'api/indices/filters');
      this.filtersData = resp.data;

      let rebalanceFrequencyMap = {};
      let assetClassMap = {};
      let instrumentTypeMap = {};
      resp.data.rebalanceFrequency.forEach(x => rebalanceFrequencyMap[x.value] = x.text );
      resp.data.instrumentType.forEach(x => instrumentTypeMap[x.value] = x.text );
      resp.data.assetClass.forEach(x => assetClassMap[x.value] = x.text );
      this.rebalanceFrequencyMap = rebalanceFrequencyMap;
      this.assetClassMap = assetClassMap;
      this.instrumentTypeMap = instrumentTypeMap;
    },
    async loadBookmarks() {
       const resp = await http.get( 'api/indices/bookmarks');
       this.bookmarks = resp.data.reduce((obj,item)=> (obj[item.indexId] = true, obj),{});
    },
    clearRecintlyViewed() {
      localStorage.setItem('index_view_history', JSON.stringify([]));
      this.indexViewHistory = [];
    },
    showImage(url){
      this.imageUrl = url;
      this.imageVisible = true;
    },
    hideImage(){
      this.imageUrl = null;
      this.imageVisible = false;
    },
    handleFileUpload() {
      //debugger; // eslint-disable-line no-debugger
      const file = this.$refs['file'].files[0];
      let formData = new FormData(); 
      formData.append('file', file); 
      this.uploadProcessing = true;
      
      http.post( 'api/indices/' + this.selectedItemId + '/image', 
        formData, { headers: { 'Content-Type': 'multipart/form-data' } } )
        .then(() => {
          this.uploadProcessing = false;
          this.hideUploadFile();
          this.load();
        })
        .catch(() => {
          console.log('FAILURE!!');
          this.uploadProcessing = false;
          this.hideUploadFile();
          this.load();
        });
    },
    uploadFile(item) {
      this.uploadVisible = true;
      this.selectedItemId = item.id;
    },
    hideUploadFile() {
      this.uploadVisible = false;
      this.selectedItemId = null;
    },
    defaultCriteria(){
      return  {
        normalisedPerformance: false,
        sortBy: 'name',
        sortByDir: 'asc',
      };
    },
    resetCriteria() {
     this.criteria = this.defaultCriteria();
     this.saveUserData();
     this.applyFilters();
    },
    async init() {
      this.isBusy = true;
      this.initializing = true;

      await this.loadPortfolioSolutions();

      this.loadUserData(); // load user after solutions will loaded ONLY

      await this.loadFilters();
    },
    async loadPortfolioSolutions() {
      const resp = await http.get('api/portfolio-solutions');
      this.portfolioSolutions = resp.data;

      this.equityMetaData = [
        {
          name: "assetClass",
          title: "Asset Class",
          solutionParameters: this.assetClassSolutionParameters,
          expanded: false,
          allSelected: false,
          indeterminate: false
        },
        {
          name: "region",
          title: "Region",
          solutionParameters: this.regionSolutionParameters,
          expanded: false,
          allSelected: false,
          indeterminate: false
        },
        {
          name: "fundamentals",
          title: "Fundamentals",
          solutionParameters: this.fundamentalsSolutionParameters,
          expanded: false,
          allSelected: false,
          indeterminate: false
        },
        {
          name: "sector",
          title: "Sector",
          solutionParameters: this.sectorSolutionParameters,
          expanded: false,
          allSelected: false,
          indeterminate: false
        }
      ];
    },
    startScreening() {
      this.screening = this.createScreeningData();
      this.screening.enabled = true;
      this.saveUserData();
    },
    cancelScreening () {
      this.screening = this.createScreeningData();
      this.saveUserData();
    },
    createScreeningData() {
      return {
          enabled: false,
          step: 0,
          screeningType: 'Correlations',
          years: 1,
          navOrReturn: 'Navs',
          targetVolatilityEnabled: false,
          targetVolatility: 0,
          items:[]
        }
    },
    addAllIndexToScreening(){
      this.items.forEach(x => this.addIndexToScreening(x));
    },
    isIndexAllowedForScreening(i) {
      if(!i.isComposed)
        return true;
      if(i.isComposed && !i.optimization || !i.optimization.calculation) {
        return true;
      }
      if(i.isComposed && i.optimization && i.optimization.calculation) {
        if(i.optimization.calculation.status === 'CalculationIsDone' || !i.optimization.calculation.status){
          return true;
        }
      }
      return false;
    },
    addIndexToScreening(i) {
      if(this.screening.items.find(a => a.id === i.id)) {
        return;
      }
      if(this.isIndexAllowedForScreening(i)) {
        let v =[... this.screening.items, { id: i.id, displayName: i.displayName }];
        v.sort((a, b) => a.displayName.localeCompare(b.displayName));
        this.screening.items = v;
        this.saveUserData();
      }
    },
    removeIndexFromScreening(i){
      let v =[... this.screening.items.filter(a => a.id !== i.id)];
      v.sort((a, b) => a.displayName.localeCompare(b.displayName));
      this.screening.items = v;
      this.saveUserData();
    },
    addTag(t) {
      this.allTags = [...(this.allTags || []), t];
      this.composedIndexBasicInfo.tags = [...(this.composedIndexBasicInfo.tags || []), t];
    },
    async startComposeIndex()  {
      this.errors = [];
      this.composeIndexEnabled = true;
      this.composeIndexItems = [];
      this.composedIndexBasicInfo = {
        name: '',
        description: '',
        assetClass: this.assetClasses.equities,
        benchMark: '',
        isTemplate: false,
        tags: []
      };
      this.composeIndexStep = 0;
      this.optimization = {
        walkForwardOptimizationEnabled: false,
        walkForwardOptimization: {
          inSamplePeriod:0,
          periodBetweenRebalancing: 0
        },
        startDate: null,
        endDate: moment(new Date()).format("DD/MM/YYYY"),
        targetVolatilityEnabled: false,
        targetVolatility: 0,
        solution:{
          values:{

          }
        }
      };

      this.selectedPortfolioSolution = this.portfolioSolutions.find(a => a.default) || this.portfolioSolutions[0] || null;

      const allTagsResponse =await http.get('api/index-tags');
      this.allTags = allTagsResponse.data;

      this.saveUserData();
    },
    cancelComposeIndex () {
      this.optimization = null;
      this.composeIndexEnabled = false;
      this.composeIndexItems = [];
      this.composeIndexStep = 0;
      this.composedIndexBasicInfo = {
        name: '',
        description: '',
        assetClass: '',
        benchMark: '',
        isTemplate: false,
        tags: []
      };
      this.loadPortfolioSolutions();
      this.saveUserData();
       this.errors = [];
    },
    portfolioSolutionChanged (){
      if(this.selectedPortfolioSolution.walkForwardOptimizationEnabled) {
        this.optimization.walkForwardOptimizationEnabled = true;
        this.optimization.walkForwardOptimization = {
          inSamplePeriod: this.selectedPortfolioSolution.walkForwardOptimization.inSamplePeriod,
          periodBetweenRebalancing: this.selectedPortfolioSolution.walkForwardOptimization.periodBetweenRebalancing
        }
      } else {
        this.optimization.walkForwardOptimizationEnabled = false;
        this.optimization.walkForwardOptimization = {
          inSamplePeriod: 0,
          periodBetweenRebalancing: 0,
        }
      }

      if(this.selectedPortfolioSolution.targetVolatilityEnabled) {
        this.optimization.targetVolatilityEnabled = this.selectedPortfolioSolution.targetVolatilityEnabled;
        this.optimization.targetVolatility = this.selectedPortfolioSolution.targetVolatility; 
      } else {
        this.optimization.targetVolatilityEnabled = false;
        this.optimization.targetVolatility = 0; 
      }

      // if(this.selectedPortfolioSolution.startDate){
      //   console.log('portfolioSolutionChanged1', this.optimization.startDate);
      //   console.log('portfolioSolutionChanged3', this.selectedPortfolioSolution.startDate);
      //   this.optimization.startDate = moment(this.selectedPortfolioSolution.startDate).format("DD/MM/YYYY");
      // }

      // if(this.selectedPortfolioSolution.endDate){
      //   console.log('portfolioSolutionChanged2', this.optimization.endDate);
      //   // this.optimization.endDate = this.selectedPortfolioSolution.endDate;
      //   this.optimization.endDate = moment(this.selectedPortfolioSolution.endDate).format("DD/MM/YYYY");
      // }
      console.log('portfolioSolutionChanged', this.optimization.startDate);
    },
    selectPortfolioSolution() {
      this.optimization.solution.values = {};
      if(this.composeIndexItems.length > 0) {
        this.portfolioSolutions.forEach(a => {
          if(a.name === 'Weights') {
            a.positionParameters.forEach(x => {
              this.composeIndexItems.forEach(p => {
                this.optimization.solution.values[x.id + p.id] = parseFloat((1 / this.composeIndexItems.length).toPrecision(p.decimals));
              });
            });
            
          } else {
            a.positionParameters.forEach(x => {
              this.composeIndexItems.forEach(p => {
                if(x.type !== 'Index') {
                  this.optimization.solution.values[x.id + p.id] = x.defaultValue || 0;
                }
                
              });
            });
          }
        
          a.solutionParameters.forEach(x => {
              if(x.type !== 'Index') {
                this.optimization.solution.values[x.id] = x.defaultValue || 0;
              }
          });
        });
        let minDate = null;
        
        this.composeIndexItems.forEach(index =>{
          if(minDate === null || minDate < index.minDate) {
            minDate = index.minDate;
          }
        });
        this.optimization.startDate = minDate ?  moment(minDate).format("DD/MM/YYYY") : null;
        this.errors = [];
        this.composeIndexStep = 1;
        this.saveUserData();
        this.$forceUpdate();

        console.log('selectPortfolioSolution', this.optimization.startDate);
      }
     
    },
    backComposeIndex () {
      if(this.composeIndexStep > 0){
        this.composeIndexStep =  this.composeIndexStep - 1;
        this.saveUserData();
      }
    },
    selectAllComposeIndexPart() {
      const newItems = this.items.filter(i => !this.composeIndexItems.find(a => a.id === i.id));
      const prepNewItems = newItems.map(i => this.prepareObjFroComposeIndex(i));
      let v = [... this.composeIndexItems, ...prepNewItems];
      //console.log(v)
      v.sort((a, b) => (a.displayName || '').localeCompare(b.displayName || ''));

      if(v.length > 100) {
        v = v.slice(0, 100);
      }

      this.composeIndexItems = v;
      this.saveUserData();
    },
    prepareObjFroComposeIndex(i) {
      let minDate = null;
      (i.netAssetValueGrowthCharts || []).forEach(chartData =>{
        (chartData.points || []).forEach(point => {
          const dt = moment(point.dateTime);
          if(minDate === null || minDate > dt) {
            minDate = dt;
          }
        });
      });
      //console.log(i);
      return { id: i.id, displayName: i.displayName, minDate: (minDate ? minDate.toDate() : null) };
    },
    selectComposeIndexPart(i) {
      if(this.composeIndexItems.find(a => a.id === i.id)) {
        return;
      }
     
      let v =[... this.composeIndexItems, this.prepareObjFroComposeIndex(i)];
      v.sort((a, b) => a.displayName.localeCompare(b.displayName));
      
       if(v.length > 100) {
        v = v.slice(0, 100);
      }

      this.composeIndexItems = v;
      console.log('composeIndexItems', v);
      this.saveUserData();
    },
    removeComposeIndexPart(i){
      let v =[... this.composeIndexItems.filter(a => a.id !== i.id)];
      v.sort((a, b) => a.displayName.localeCompare(b.displayName));
      this.composeIndexItems = v;
      this.saveUserData();
    },
    inputComposedIndexName () {
      if(!this.optimization.startDate){
        this.errors = ['Optimmization date range is required.'];
        return;
      }
       this.errors = [];
      this.composeIndexStep = 2;
      this.saveUserData();
    },
    submitComposedIndex () {
      if(!this.composedIndexBasicInfo.name)
        return;
        const dto = {
           
          name: this.composedIndexBasicInfo.name,
          displayname: this.composedIndexBasicInfo.name,
          description: this.composedIndexBasicInfo.description,
          assetClass: this.composedIndexBasicInfo.assetClass,
          benchMark: this.composedIndexBasicInfo.benchMark,
          isTemplate: this.composedIndexBasicInfo.isTemplate,
          isComposed: true,
          publicIndex: this.publicIndex,
          children: this.composeIndexItems.map(a => a.id),
          systemValues: {
            '$TAGS$': JSON.stringify(this.composedIndexBasicInfo.tags || [])
          },
          optimization: {
            walkForwardOptimizationEnabled: this.optimization.walkForwardOptimizationEnabled,
            walkForwardOptimization: {
              inSamplePeriod: (this.optimization.walkForwardOptimization.inSamplePeriod || 0).toFixed(),
              periodBetweenRebalancing:  (this.optimization.walkForwardOptimization.periodBetweenRebalancing || 0).toFixed()
            },
            targetVolatilityEnabled: this.optimization.targetVolatilityEnabled,
            targetVolatility: this.optimization.targetVolatility,
            solution: this.selectedPortfolioSolution.id,
            parameters:[],
            startDate: this.optimization.startDate ? moment(this.optimization.startDate, 'DD/MM/YYYY').toDate() : null,
            endDate: this.optimization.endDate ? moment(this.optimization.endDate, 'DD/MM/YYYY').toDate() : null,
            shouldBeRecalculated: true,
          }
        };
        this.composeIndexItems.forEach(i =>
          {
            this.selectedPortfolioSolution.positionParameters.forEach(p =>{
              if(p.type === 'Index') {
                dto.optimization.parameters.push({ solutionParameter: p.id, index: i.id, stringValue: this.optimization.solution.values[p.id + i.id] });
              } else {
                dto.optimization.parameters.push({ solutionParameter: p.id, index: i.id, value: this.optimization.solution.values[p.id + i.id] });
              }
            });
          }
        );
        this.selectedPortfolioSolution.solutionParameters.forEach(p =>{
          const paramValue = this.optimization.solution.values[p.id];
          const parameterObject = {
            solutionParameter: p.id,
            [p.type === 'Index' ? 'stringValue' : 'value']: paramValue,
          };

          if (this.solutionParametersNoEquityMetaData.includes(p)) { // p is not EquityMetaData
            dto.optimization.parameters.push(parameterObject);
          } else {
            if (paramValue !== null && this.equityMetaDataCheckBoxes[p.title]) { // p is EquityMetaData and has value and row checkbox selected
              dto.optimization.parameters.push(parameterObject);
            }
          }
        });
        this.composedIndexSubmiting = true;
        
        const req = http.post('api/indices', dto);
        req.then((resp) => {
          this.composedIndexSubmiting = false;
          this.cancelComposeIndex();
          this.load();
          if(!dto.isTemplate) {
            this.$router.push({ name: "index-view", params: { id: resp.data.id } });
          }
        })
        .catch(error => {
          this.composedIndexSubmiting = false;
          this.errors = parseErrors(error);
        });
    },
    submitScreening () {
      const dto = {
        ...this.screening,
        indices: this.screening.items.map(x => x.id),
        targetVolatility: this.screening.targetVolatility || 0,
        items: undefined
      };
       
      this.screeningSubmiting = true;
        
      const req = http.post('api/screening', dto);
      req.then((resp) => {
        this.screeningSubmiting = false;
        this.cancelScreening();
        this.load();
        this.$router.push({ name: "screening", params: { id: resp.data.id } });
      })
      .catch(error => {
        this.screeningSubmiting = false;
        this.errors = parseErrors(error);
      });
    },
    loadUserData() {
      let dataStr = localStorage.getItem('indices_list_data');
     
      //debugger; // eslint-disable-line no-debugger
      if(dataStr) {
        const data = JSON.parse(dataStr);
       
        // this.currentPage = data.currentPage;
        this.currentPage = data.currentPage;
        this.viewMode = data.viewMode;
        this.perPage = data.perPage || 20;
        
        if(!data.statColumns.indexSubType) {
          data.statColumns.indexSubType = {
            visible: true
          };
        }

        if(!data.statColumns.statUpdatedAt) {
          data.statColumns.statUpdatedAt = {
            visible: true
          };
        }

        this.statColumns = data.statColumns;
        if(!this.statColumns.externalId) {
          this.statColumns.externalId = {
            visible: false
          }
        }
        this.screening = data.screening || this.createScreeningData();     
        this.criteria = data.criteria;     
        this.composeIndexEnabled = data.composeIndexEnabled || false;  
        this.composeIndexItems = data.composeIndexItems || [];
        this.composeIndexStep = data.composeIndexStep || 0;
        this.mainChartYAxisLogarithmicType = data.mainChartYAxisLogarithmicType || false,
        this.composedIndexBasicInfo = data.composedIndexBasicInfo || {
          name: '',
          description: '',
          assetClass: '',
          benchMark: '',
          isTemplate: false,
          tags: []
        };
        this.optimization = data.optimization || {
          startDate: null,
          endDate: moment(new Date()).format("DD/MM/YYYY"),
          walkForwardOptimizationEnabled: false,
          walkForwardOptimization: {
            inSamplePeriod:0,
            periodBetweenRebalancing: 0
          },
          targetVolatilityEnabled: false,
          targetVolatility: 0,
          solution: {
            values: {

            }
          }
        };
      
        if(data.selectedPortfolioSolution) {
          this.selectedPortfolioSolution = this.portfolioSolutions.find(a => a.id === data.selectedPortfolioSolution.id);
        }
        
        if(!this.selectedPortfolioSolution) {
          this.selectedPortfolioSolution = this.portfolioSolutions[0];
        }  
      }
    
    },
    saveUserData() {
      const data = {
        version: 1,
        currentPage: this.currentPage,
        perPage: this.perPage,
        viewMode: this.viewMode,
        statColumns: this.statColumns,
        criteria: this.criteria,
        composeIndexEnabled: this.composeIndexEnabled,
        composeIndexItems: this.composeIndexItems,
        composeIndexStep: this.composeIndexStep,
        selectedPortfolioSolution: this.selectedPortfolioSolution,
        optimization: this.optimization,
        screening: this.screening,
        composedIndexBasicInfo: this.composedIndexBasicInfo,
        mainChartYAxisLogarithmicType: this.mainChartYAxisLogarithmicType
      };
      localStorage.setItem('indices_list_data', JSON.stringify(data));
    },
    async applyFilters(){
      console.log('applyFilters');
      if( this.currentPage != 1){
        this.currentPage = 1;
        this.saveUserData();
      } else {
        this.saveUserData();
        this.load();
      }
    },
    async loadKeys(){
    
      this.selectedKeyValue = null;
      this.selectedKey = null;
      const resp = await http.get(`api/indices/system-values/keys`);
      this.keys = resp.data;
    
    },
    prepareNetAssetValueGrowthCharts(data) {
     const colors = ['#434348', '#7cb5ec', '#90ed7d', '#f7a35c', '#8085e9', '#f15c80', '#e4d354', '#2b908f', '#f45b5b', '#91e8e1'];
      let i = 0;
    
     let chartSeries =  data.netAssetValueGrowthCharts;
      if(!chartSeries)
          return;

      chartSeries.forEach(x => x.chartVisible = true);
      chartSeries
        .filter(x => x.indexId === data.id && !x.isLive)
        .forEach(x => x.color = "#FE6C2D");
      chartSeries
        .filter(x => x.indexId === data.id && x.isLive)
        .forEach(x => x.color = "#7d096d");

      chartSeries
        .filter(x => x.indexId !== data.id)
        .forEach(x => {
          x.color = colors[i++];
          if(i === colors.length) i = 0;
        });
    },
   
    async addBookmark(indexId) {
      let o = {...this.bookmarks};
      o[indexId] = true;
      this.bookmarks = o;
     
      await http.post('api/indices/' + indexId + '/bookmarks');
    },
    async removeBookmark(indexId){
      let o = {...this.bookmarks};
      o[indexId] = false;
      this.bookmarks = o;
      await http.delete('api/indices/' + indexId + '/bookmarks');
    },
    createChartOptions(element) {
      let chartSeries =  element.netAssetValueGrowthCharts || [];
      if(!chartSeries)
          return;
        
      let seriesOptions = [];
      for(let i = 0; i < chartSeries.length; i++) {
        const source = chartSeries[i];
        if(source.indexId === element.id)
        {
          seriesOptions.push({
            name: source.title + (source.isLive ? ' [Live]' : ''),
            label: source.title,
            data: (source.points || []).map(x => [x.timeStamp, x.value]),
            color: source.color,
            lineWidth: source.isLive ? 3 : 1
          });
        }
      }

      return {
        credits: {
          enabled: false
        },
        legend: {
          enabled: false
        },
        accessibility:{
          enabled: false
        },
        annotations: {
          visible: false
        },
        boost:{
          enabled: false
        },
        exporting:{
          enabled: false
        },
        navigator:{
          enabled: false
        },
        plotOptions: {
          series: {
            marker: {
              enabled: false
            }
          },
        },
         
        yAxis: {
          type: this.mainChartYAxisLogarithmicType ? 'logarithmic' : null,
          labels: {
            formatter: function () {
              return (this.value > 0 ? '' : '') + this.value;
            }
          },
          plotLines: [{
            value: 0,
              width: 2,
              color: 'silver'
          }]
        },
        
        tooltip: {
          pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b><br/>',
          valueDecimals: 2
        },
        colors: ['#434348', '#7cb5ec', '#90ed7d', '#f7a35c', '#8085e9', '#f15c80', '#e4d354', '#2b908f', '#f45b5b', '#91e8e1'],
        series: seriesOptions
      };
    },
    rebuildNetAssetValueGrowthCharts() {
      this.items.forEach(x => {
        x.chartOptions = this.createChartOptions(x);
      });
    },
    paramIsNumberInput(param){
      return param.name === 'percentile' || param.name === 'explainedVariance';
    },
    paramIsPercentageNumberInput(param){
      return param.type !== 'Index' && param.name !== 'MVOLambda' && param.name !== 'percentile' && param.name !== 'explainedVariance';
    },
    formatPercentageInput(v) {
      if(!v) {
        v = 0;
      } else {
        if(v.endsWith('.')) return v;
        v = parseFloat(parseFloat(v.toString()).toFixed(1));
      }
      return v;
    },
    formatToPercentage(v) {
      return parseFloat((v*100).toFixed(1));
    },
    setValueFromPercentage(id, v) {
      //console.log("setValueFromPercentage 1", v);
      if(!v) {
        v = 0;
      } else {
        v = parseFloat(parseFloat(v.toString()).toFixed(1));
      }
      this.optimization.solution.values[id] = parseFloat((v/100).toFixed(3));
      //console.log("setValueFromPercentage 2", this.optimization.solution.values[id]);
    },
    async load(silence)  {
     
      let dataStr = localStorage.getItem('index_view_history');
      
      let indexViewHistory = [];
      if(dataStr) {
        indexViewHistory = JSON.parse(dataStr);
      } else {
        indexViewHistory = [];
      }
      this.indexViewHistory = indexViewHistory.reverse();

      if(silence !== true) {
        this.isBusy = true;
      }

      const currentPage = this.currentPage;
      let sv = {};
      if(this.criteria.selectedKey){
        sv[this.criteria.selectedKey] = this.criteria.selectedKeyValue;
      }
      await this.loadBookmarks();
      console.log('filterCriteria', this.filterCriteria);

      const dto = {
        take: this.perPage,
        skip: this.perPage * (currentPage - 1),
        keyword:  this.filterCriteria.keyword,
        name: this.filterCriteria.name,
        systemValues:sv,
        providers: (this.filterCriteria.provider || []).map(x => x),
        bookmarksOnly:  this.showOnlyBookmarks || false,
        portfolioSolutions: (this.filterCriteria.portfolioSolution || []).map(x => x),
        markets: this.filterCriteria.market,
        indexTypes:  this.filterCriteria.indexType,
        indexSubTypes: this.filterCriteria.indexSubType,
        instrumentTypes: this.filterCriteria.instrumentType,
        assetClasses: this.filterCriteria.assetClass,
        rebalanceFrequency: this.filterCriteria.rebalanceFrequency,
        sortBy: this.criteria.sortBy,
        sortByDir: this.criteria.sortByDir,
        mtd: this.filterCriteria.mtd,
        ytd: this.filterCriteria.ytd,
        annualisedReturn: this.filterCriteria.annualisedReturn,
        realisedVolatility: this.filterCriteria.realisedVolatility,
        downsideVolatility: this.filterCriteria.downsideVolatility,
        maxDrawdown: this.filterCriteria.maxDrawdown,
        winningMonths:this.filterCriteria.winningMonths,
        sharpeRatio: this.filterCriteria.sharpeRatio,
        sortinoRatio: this.filterCriteria.sortinoRatio,
        calmarRatio: this.filterCriteria.calmarRatio,
        normalisedPerformance: this.criteria.normalisedPerformance,
        singleIndices: this.filterCriteria.singleIndices,
        compositeIndices: this.filterCriteria.compositeIndices,
        onlyMy: this.filterCriteria.onlyMy,
        tags: this.filterCriteria.tags || []
      };
      if(dto.maxDrawdown.from === ''){
        dto.maxDrawdown.from = null;
      } 
      if(dto.maxDrawdown.till === ''){
        dto.maxDrawdown.till = null;
      }
      if(dto.annualisedReturn.from === ''){
        dto.annualisedReturn.from = null;
      } 
      if(dto.annualisedReturn.till === ''){
        dto.annualisedReturn.till = null;
      }
      if(dto.realisedVolatility.from === ''){
        dto.realisedVolatility.from = null;
      } 
      if(dto.realisedVolatility.till === ''){
        dto.realisedVolatility.till = null;
      }
      if(dto.winningMonths.from === ''){
        dto.winningMonths.from = null;
      } 
      if(dto.winningMonths.till === ''){
        dto.winningMonths.till = null;
      }
      if(dto.sharpeRatio.from === ''){
        dto.sharpeRatio.from = null;
      } 
      if(dto.sharpeRatio.till === ''){
        dto.sharpeRatio.till = null;
      }
      if(dto.sortinoRatio.from === ''){
        dto.sortinoRatio.from = null;
      } 
      if(dto.sortinoRatio.till === ''){
        dto.sortinoRatio.till = null;
      }
      if(dto.calmarRatio.from === ''){
        dto.calmarRatio.from = null;
      } 
      if(dto.calmarRatio.till === ''){
        dto.calmarRatio.till = null;
      }
     
      const resp = await http.post(`api/indices/search`, dto);
     
      resp.data.items.forEach(element => {
        const tagsSystemValue = (element.systemValues || {})['$TAGS$'];
        if(tagsSystemValue) {
          try {
            element.tags = JSON.parse(tagsSystemValue);
          }catch(e) {
            console.error(e)
          }
        }

        this.prepareNetAssetValueGrowthCharts(element);
        element.chartOptions = this.createChartOptions(element);
      });

      this.items = resp.data.items;
      this.totalItems = resp.data.total;
     
      if(silence !== true) {
        this.isBusy = false;
      } 
      this.initializing = false;
    },
    confirmDelete(item) {
      Swal.fire({
        title: this.$t("common.areYouSure"),
        text: this.$t("common.youCannotRevertOp"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: this.$t("common.yesDelete")
      }).then(result => {
        if (result.value) {
         this.handleDelete(item);
        }
      });
    },
    handleDelete(item) {
     http.delete(`api/indices/${item.id}`).then(() => {
        Swal.fire(this.$t("common.deleted"), this.$t("common.recWasDeleted"), "success");
        this.load();
      })
      .catch(error => {
          this.errors = parseErrors(error);
          Swal.fire({
            title: 'Unable to delete',
            text: this.errors[0],
            icon: "warning",
            confirmButtonColor: "#34c38f",
            cancelButtonColor: "#f46a6a",
            confirmButtonText: 'Ok'
          });
      });
    },
    getImageUrl(item){
      return item ? process.env.VUE_APP_BASE_URL + "/api/indices/" + item.id + "/image" : "/";
    },
    sortByColumn(column){
      if(this.criteria.sortBy === column)
        return;

      this.criteria.sortBy = column;
      this.saveUserData();
      this.load();
    },
    sortByDir(dir) {
      if(this.criteria.sortByDir === dir)
        return;
      this.criteria.sortByDir = dir;
      this.saveUserData();
      this.load();
    },
    sortBy(column) {
      if(this.criteria.sortBy === column) {
        if(this.criteria.sortByDir === 'asc') {
          this.criteria.sortByDir = 'desc';
        } else {
          this.criteria.sortByDir = 'asc';
        }
      } else {
        this.criteria.sortBy = column;
        this.criteria.sortByDir = 'asc';
      }
      this.saveUserData();
      this.load();
    },
    showList(){
      this.viewMode = 'list';
    },
    showGrid(){
      this.viewMode = 'grid';
      this.rebuildNetAssetValueGrowthCharts();
    },
    getSolutionParameterTooltip(solutionParameterName){
      const cleanedName = solutionParameterName.toLowerCase().split(" ").join("");
      const toolTipDescription = this.$t('pages.indexView.optimization.solutionParameters_' + cleanedName + 'Tooltip');
      return toolTipDescription;
    },
    getPositionParameterTooltip(portfolioName, positionParameterName){
      const cleanedPortName = portfolioName.toLowerCase().split(" ").join("");
      const cleanedposParName = positionParameterName.toLowerCase().split(" ").join("");

      var toolTipDescription;
      if(cleanedPortName === "clusteringriskparity" || cleanedPortName === "factormodel" || cleanedPortName === "eigenportfolio"){
        toolTipDescription = this.$t('pages.indexView.optimization.positionParameters_' + cleanedposParName + 'Tooltip_1');
      } 
      else if(cleanedPortName === "constrainedtacticalassetallocation" || cleanedPortName === "constrainoptimalcombination" || cleanedPortName === "multiconstrainttacticalassetallocation" 
      || cleanedPortName === "multiconstraintmeanvarianceoptimisation" || cleanedPortName === "multiconstrainttrackingsolution"){
        toolTipDescription = this.$t('pages.indexView.optimization.positionParameters_' + cleanedposParName + 'Tooltip_2');
      } 
      else if(cleanedPortName === "mixed-integeroptimization"){
        toolTipDescription = this.$t('pages.indexView.optimization.positionParameters_mixedintoptimisation_' + cleanedposParName + 'Tooltip');
      }
      else{
        toolTipDescription = this.$t('pages.indexView.optimization.positionParameters_' + cleanedposParName + 'Tooltip');
      }
      return toolTipDescription;
    },
    subClassContainsOverride(solutionParameters) {
      return solutionParameters.some(param => param.name.includes('Override'));
    },
    solutionParameterPairs(solutionParameters) {
      const pairs = {};
      solutionParameters.forEach((x) => {
        const type = x.name.split('_')[2];
        const key = x.title;
        if (!pairs[key]) {
          pairs[key] = {};
        }
        pairs[key][type] = x;
      });
      return pairs;
    },
    toggleAllSubClassParams(subClass) {
      const selected = subClass.allSelected;
      subClass.solutionParameters.forEach((param) => {
        this.$set(this.equityMetaDataCheckBoxes, param.title, selected);
      });
    },
    calculateSubClassCheckbox(subClass) {
      const paramsChecked = subClass.solutionParameters.reduce((sum, param) => {
        if (this.equityMetaDataCheckBoxes[param.title] == true) {
          sum += 1;
        }
        return sum;
      }, 0);

      if (paramsChecked === 0) {
        subClass.indeterminate = false
        subClass.allSelected = false
      } else if (paramsChecked === subClass.solutionParameters.length) {
        subClass.indeterminate = false
        subClass.allSelected = true
      } else {
        subClass.indeterminate = true
        subClass.allSelected = false
      }
    }
  }
};
</script>

<template>
  <Layout>
    <div class="marketplace">
      <div class="row">
        <div class="col-12">
          <div class="page-title-box d-flex align-items-center justify-content-between">
            <h4 class="mb-0">
              <span  class="title-content">
                {{ title }} <i v-if="isBusy"  class="fa fa-spin fa-spinner ml-2"></i>
              </span>
            </h4>
            <div class="page-title">
              <div class="btn-toolbar mb-2">
                <div>
                  <div class="btn-group  mb-2 mr-1 mb-sm-0" v-if="isAdmin && !composeIndexEnabled">
                    <router-link to="/indices/new/edit"  class="btn btn-c8 ">
                      <span class="btn-content">
                        <i class="fa fa-plus mr-2"></i>New index
                      </span>
                    </router-link>
                  
                    <button type="button"  class="btn btn-c8 revers" @click="startComposeIndex" v-if="!screening.enabled">
                        <span class="btn-content">
                          <i class="fa fa-plus mr-2"></i>Combine 
                        </span>
                      </button>
                  </div>
                  <div class="btn-group  mb-2 mr-1 mb-sm-0" v-if="!isAdmin && !composeIndexEnabled && !screening.enabled">
                    <button type="button"  class="btn btn-c8" @click="startComposeIndex">
                        <span class="btn-content">
                          <i class="fa fa-plus mr-2"></i>Combine 
                        </span>
                      </button>
                  </div>

                  <div class="btn-group mb-2 mr-2 mb-sm-0 ml-2" v-if="!composeIndexEnabled && !screening.enabled">
                    <button type="button"  class="btn btn-c8" @click="startScreening">
                      <span class="btn-content">
                      <i class="fas fa-chart-line mr-2"></i>Screening
                      </span>
                    </button>
                    <router-link  v-if="!composeIndexEnabled && !screening.enabled" :to="{ name: 'screening-history'}" class="btn btn-c8 revers"> 
                      <span class="btn-content">
                        <i class="fas  fa-history mr-2"></i>History
                      </span>
                    </router-link> 
                  </div>  

                  <div class="btn-group  mb-2 mr-1 mb-sm-0" v-if="!composeIndexEnabled && !screening.enabled">
                    <router-link  :to="{ name: 'upload-index'}" class="btn btn-c8"> 
                      <span class="btn-content">
                        <i class="fas  fa-upload mr-2"></i>Upload
                      </span>
                    </router-link> 
                  </div> 
                  <!-- <div class="btn-group  mb-2 mr-1 mb-sm-0" v-if="!composeIndexEnabled && !screening.enabled">
                    <router-link  :to="{ name: 'portfolio-questionnaire'}" class="btn btn-c8"> 
                      <span class="btn-content">
                        <i class="mdi mdi-head-question-outline mr-2"></i>Portfolio Questionnaire
                      </span>
                    </router-link> 
                  </div>  -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

     

      <div class="fc">
        <div class="filter-card"
        >
          <filter-card 
              v-if="filtersData"
              :values="filtersData" 
              :persistent="true" 
              @apply="onFilterApply"
              @load="onFilterApply"
              :reset-button-no-action="false"
              apply-button-title="Apply"
            >
          </filter-card> 
        </div>
        
        

        <div v-if="isBusy" style="position:absolute;left:0; top:0; bottom:0; right:0;background:white;opacity:0.5;"></div>
      </div>

      <div class="lc" :class="{'wide': composeIndexEnabled && composeIndexStep > 0}">
        <div class="recently-view-card" v-if="!screening.enabled  && !composeIndexEnabled">
          <div class="card cardc8" >
            <div class="card-content">
              <div class="card-header bg-transparent">
                <div class="row">
                  <div class="col">
                      <h5 class="mb-0 float-left">Recently viewed</h5>
                    <button type="button" class="float-right btn btn-sm btn-link" @click="clearRecintlyViewed">
                      Clear
                    </button>
                  </div>
                </div>
              </div>
           
              <div class="card-body p-0">
                <simplebar style="max-height:100%;">
                  <ul>
                    <li v-for="v in indexViewHistory" :key="v.id">
                      <router-link
                      :to="{ name: 'index-view', params: { id: v.id}}"
                    
                      style="display: block"
                    >
                      {{v.displayName}}
                      
                    </router-link>
                    </li>
                  </ul>
                </simplebar>
              </div>
            </div>
          </div>
        </div>
        <div class="card cardc8" v-if="screening.enabled">
          <div class="card-content">
             <div class="card-header bg-transparent border-bottom">
              <h4 class="card-title mb-4">Select indices for screening</h4>
            </div>
            <div class="card-body"  style="overflow: auto"> 
              <div v-if="screening.step === 0" style="height:100%; ">
                <div class="alert alert-info" v-if="screening.items.length === 0">Please select indices below.</div>
                <div style="height:50%; overflow:auto;">
                  <table class="table table-striped table-sm table-hover " >
                    <tbody>
                      <tr v-for="i in screening.items" :key="i.id">
                        <td>{{i.displayName}}</td>
                        <td style="max-width:30px; width:30px;">
                          <a href="javascript:void(0)" @click="removeIndexFromScreening(i)">
                            <i class="fa fa-times"/>
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div style="height:45%; overflow:auto;" class="mt-4">
                  <b-form-group>
                    <label>Type</label>
                    <select class="form-control" v-model="screening.screeningType" @change="screening.year = 1">
                      <option value="Correlations">Correlations</option>
                      <option value="RollingCorrelations">Rolling correlations</option>
                      <option value="PerformanceStatistics">Performance statistics</option>
                      <option value="PerformanceData">Performance data</option>
                    </select>
                  </b-form-group>
                   <b-form-group>
                     <label>Years</label>
                      <select class="form-control" v-model="screening.years">
                        <option :value="1">1 Year</option>
                        <option :value="3">3 Years</option>
                        <option :value="5" v-if="screening.screeningType !== 'RollingCorrelations'">5 Years</option>
                        <option :value="10">10 Years</option>
                      </select>
                    </b-form-group>
                    <b-form-group>
                      <label>Navs or Return</label>
                      <select class="form-control" v-model="screening.navOrReturn">
                        <option value="Navs">Navs</option>
                        <option value="Return">Return (%)</option>
                      </select>
                    </b-form-group>
                    <div v-if="screening.screeningType === 'PerformanceStatistics' || screening.screeningType === 'PerformanceData'"> 
                      <b-form-group 
                        label="Target Volatility (%)">
                        <div class="input-group mb-3">
                          <span class="input-group-text" id="basic-addon1"><input type="checkbox" v-model="screening.targetVolatilityEnabled"/></span>
                          <input type="text" class="form-control" v-model.number="screening.targetVolatility">
                        </div>
                      </b-form-group>
                    </div>
                  </div>
                </div>
                
            </div>
            <div class="buttons">
              <div class="btn-group float-left" >
                <button type="button"  class="btn btn-outline-secondary  mb-2"
                @click="cancelScreening">Cancel</button>
              </div>
              <!-- <div class="btn-group float-right" v-if="screening.step === 0" >
                <button type="button"   class="btn btn-primary mb-2" :disabled="screening.items.length === 0"> Next<i class="fa fa-chevron-right ml-1"></i></button>
              </div> -->
              <div class="btn-group float-right">
                <button type="button" 
                  @click="submitScreening"  
                  
                  class="btn btn-success mb-2" 
                  :disabled="screening.items.length === 0 || screeningSubmiting"> 
                  <i class="fa fa-save mr-1" :disabled="!screeningSubmiting"></i>
                  <i class="fa fa-spin fa-spinner mr-1"  v-if="screeningSubmiting"></i>
                  Create
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="card cardc8" v-if="composeIndexEnabled">
          <div class="card-content">
            <div class="card-header bg-transparent border-bottom">
              <h4 class="card-title mb-4 " v-if="composeIndexStep === 0">Select indices</h4>
              <h4 class="card-title mb-4" v-if="composeIndexStep === 1">Parameters</h4>
              <h4 class="card-title mb-4"  v-if="composeIndexStep === 2">Index name and description</h4>
            </div>
            
            
            <div class="card-body" v-if="composeIndexStep === 0" >
              <div  style="height:100%; overflow:auto">
                <div class="alert alert-info" v-if="composeIndexItems.length === 0">Please select indices below.</div>
                <div>
               
               <table class="table table-striped table-sm table-hover" >
                  <tbody>
                    <tr v-for="i in composeIndexItems" :key="i.id">
                      <td>{{i.displayName}}</td>
                      <td style="max-width:30px; width:30px;">
                        <a href="javascript:void(0)" @click="removeComposeIndexPart(i)">
                          <i class="fa fa-times"></i>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
                </div>
              </div>
            </div>
             <div class="card-body" v-if="composeIndexStep === 1" style="overflow: auto">
               <b-alert
                  variant="danger"
                  class="mt-3"
                  v-if="errors.length > 0"
                  show
                  dismissible
                  >
                  <ul>
                    <li v-for="e in errors" :key="e">{{e}}</li>
                  </ul>
                </b-alert>
                <div class="form-group">
                  <label>Portfolio Solution</label>
                    <select class="form-control" v-model="selectedPortfolioSolution" @change="portfolioSolutionChanged">
                      <option v-for="(sol, $index) in portfolioSolutions" :key="$index" :value="sol">{{sol.title}}</option>
                    </select>
                    <p class="alert alert-info mt-2" v-if="selectedPortfolioSolution" v-html="selectedPortfolioSolution.descriptionHtml">
                      
                    </p>

                  <div v-if="selectedPortfolioSolution" >
                    <div v-if="selectedPortfolioSolution.name === 'MultiConstraint Equity Tactical Asset Allocation'">
                      <div class="mb-2" v-for="subClass in equityMetaData" :key="subClass.name">
                        <b-button block
                                v-b-toggle="'subclass-form-' + subClass.name"
                                variant="light"
                                class="d-flex justify-content-between align-items-center">
                          <span>{{ subClass.title }}</span>
                          <span>
                            <i :class="[{'fa-rotate-180': subClass.expanded}, 'fas fa-chevron-down']"></i>
                          </span>
                        </b-button>
                        <b-collapse :id="'subclass-form-' + subClass.name" v-model="subClass.expanded" class="mt-2">
                          <table class="table table-striped table-sm table-hover">
                            <thead>
                              <tr>
                                <th style="width:60px;max-width:60px;">
                                  <b-form-checkbox
                                    class="mx-2 my-1"
                                    plain
                                    v-model="subClass.allSelected"           
                                    :indeterminate="subClass.indeterminate"
                                    @change="toggleAllSubClassParams(subClass)"
                                  /> 
                                </th>
                                <th>{{ subClass.title }}</th>
                                <th>Min</th>
                                <th>Max</th>
                                <th v-if="subClassContainsOverride(subClass.solutionParameters)">Override</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(pair, solutionParameterName) in solutionParameterPairs(subClass.solutionParameters)" :key="solutionParameterName">
                                <td  style="width:60px;max-width:60px;">
                                  <input type="checkbox" class="m-2" v-model="equityMetaDataCheckBoxes[solutionParameterName]" @update="$forceUpdate()"/>
                                </td>
                                <td>
                                  <span class="mr-1">{{ solutionParameterName }}</span>
                                  <span v-if="paramIsPercentageNumberInput(pair.Min)">(%)</span>
                                </td>
                                <td v-for="(param, paramName) in { 'Min': pair.Min, 'Max': pair.Max, 'Override': pair.Override }" :key="paramName">
                                  <div class="input-group" v-if="paramName === 'Override' ? pair.Override !== undefined : true">
                                    <b-form-input 
                                      v-if="param.type !== 'Index'" 
                                      :type="param.type !== 'Index' ? 'number' : 'text'" 
                                      :disabled="!equityMetaDataCheckBoxes[solutionParameterName]"
                                      :style="{ opacity: !equityMetaDataCheckBoxes[solutionParameterName] ? '0.5' : '1' }"
                                      :formatter="formatPercentageInput"
                                      :step="(param.type !== 'Float' ? 1 : 0.1)" 
                                      :min="(param.min || 0) * 100" 
                                      :max="(param.max || 1) * 100"
                                      :value="formatToPercentage(optimization.solution.values[param.id])"
                                      @update="$forceUpdate()"
                                      @change="(value) => setValueFromPercentage(param.id, value)"
                                    />
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </b-collapse>
                      </div>
                    </div>
                    <div class="mt-2">
                      <div class="form-group" v-for="p in solutionParametersNoEquityMetaData" :key="p.id">
                        <div>
                          <label class="small mr-2">
                            {{p.title}}
                            <span v-if="paramIsPercentageNumberInput(p)">
                              (%)
                            </span>
                          </label>
                          <span v-b-tooltip.hover.html="getSolutionParameterTooltip(p.name)" triggers="hover click">
                            <i class="mdi mdi-information-variant" style="background-color:#eff2f7;border-radius:50%"/>
                          </span>
                        </div>
                        <div>
                          <b-form-input
                            v-if="paramIsNumberInput(p)"
                            type="number" 
                            v-model.number="optimization.solution.values[p.id]" 
                            :min="p.min" 
                            :max="p.max" 
                            :step="(p.type !== 'Float' ? 1 : (p.decimals === 1 ? 0.1 : 0.01))"
                          />
                          <b-form-input
                            v-if="paramIsPercentageNumberInput(p)"
                            type="number" 
                            :formatter="formatPercentageInput"
                            :step="(p.type !== 'Float' ? 1 : 0.1)" 
                            :min="(p.min || 0) * 100" 
                            :max="(p.max || 1) * 100"
                            :value="formatToPercentage(optimization.solution.values[p.id])"
                            @change="(value) => setValueFromPercentage(p.id, value)"
                          />
                          <b-form-input
                            v-if="p.name === 'MVOLambda'" 
                            type="number" 
                            v-model.number="optimization.solution.values[p.id]" 
                            :min="p.min" 
                            :max="1e6" 
                            :step="0.1"
                          />

                          <b-form-input
                            v-if="p.type === 'Index'" 
                            type="text" 
                            v-model.number="optimization.solution.values[p.id]" 
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  <div>
                    <label class="mt-4 mr-2">
                      <input type="checkbox" class="mr-2" v-model="optimization.walkForwardOptimizationEnabled"/> 
                        Walk Forward Optimisation
                    </label>
                    <span v-b-tooltip.hover.html="$t('pages.indexView.optimization.walkForwardOptimizationTooltip')" triggers="hover click">
                      <i class="mdi mdi-information-variant" style="background-color:#eff2f7;border-radius:50%"/>
                    </span>
                  </div>
                  

                  <div class="row no-gutters">
                    <div class="col-6 pr-1">
                      <div class="form-group" v-if="optimization.walkForwardOptimization">
                        <div>
                          <label class="small mr-2">
                            In Sample Period (Months)
                          </label>
                          <span v-b-tooltip.hover.html="$t('pages.indexView.optimization.walkForwardOptimization_inSamplePeriodTooltip')" triggers="hover click">
                            <i class="mdi mdi-information-variant" style="background-color:#eff2f7;border-radius:50%"/>
                          </span>
                        </div>
                        <div>
                          <b-form-input
                            type="number" 
                            :disabled="!optimization.walkForwardOptimizationEnabled"
                            v-model.number="optimization.walkForwardOptimization.inSamplePeriod" 
                            :step="1"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-6 pl-1">
                      <div class="form-group" v-if="optimization.walkForwardOptimization">
                        <div>
                          <label  class="small mr-2">
                            Period between Rebalancing (Months)
                          </label>
                          <span v-b-tooltip.hover.html="$t('pages.indexView.optimization.walkForwardOptimization_periodBetweenRebalancingTooltip')" triggers="hover click">
                            <i class="mdi mdi-information-variant" style="background-color:#eff2f7;border-radius:50%"/>
                          </span>
                        </div>
                        <div>
                          <b-form-input 
                            :disabled="!optimization.walkForwardOptimizationEnabled"
                            type="number" 
                            :step="1"
                            v-model.number="optimization.walkForwardOptimization.periodBetweenRebalancing" 
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  <label class="mt-4 ">
                    <input type="checkbox" class="mr-2" v-model="optimization.targetVolatilityEnabled"/> 
                    <span class="mr-2">
                      Target Volatility (%):
                    </span>
                    <span v-b-tooltip.hover.html="$t('pages.indexView.optimization.targetVolatilityTooltip')" triggers="hover click">
                      <i class="mdi mdi-information-variant" style="background-color:#eff2f7;border-radius:50%"/>
                    </span>
                  </label>
                
                  <div class="form-group">
                    <div>
                      <b-form-input 
                        :disabled="!optimization.targetVolatilityEnabled"
                        type="number" 
                        :min="0"
                        :max="100"
                        :step="1"
                        v-model.number="optimization.targetVolatility" 
                      />
                    </div>
                  </div>
                  
                  <label class="mt-4 ">
                    Optimization date
                  </label>
                  <div class="row no-gutters">
                    <div class="col-lg-6 pr-1">
                      <div>
                        <label  class="small mr-2">
                          Start
                        </label>
                        <span v-b-tooltip.hover.html="$t('pages.indexView.optimization.startDateTooltip')" triggers="hover click">
                          <i class="mdi mdi-information-variant" style="background-color:#eff2f7;border-radius:50%"/>
                        </span>
                      </div>
                      <div>
                        <b-input-group class="mb-3">
                          <b-form-input
                            v-model="optimization.startDate" 
                            type="text"
                            placeholder="DD/MM/YYYY"
                            autocomplete="off"
                          ></b-form-input>
                          <b-input-group-append>
                            <b-form-datepicker 
                              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                              today-button
                              locale="en-US"
                              button-only
                              top
                              reset-button
                              close-button
                              @input="(v) => optimization.startDate = parseDatePicker(v)"
                              class="mb-2">
                            </b-form-datepicker>
                          </b-input-group-append>
                        </b-input-group>
                      </div>
                    
                    </div>
                    <div class="col-lg-6 pl-1">
                      <div>  
                        <label class="small mr-2">
                          End
                        </label>
                        <span v-b-tooltip.hover.html="$t('pages.indexView.optimization.endDateTooltip')" triggers="hover click">
                            <i class="mdi mdi-information-variant" style="background-color:#eff2f7;border-radius:50%"/>
                        </span>
                      </div> 
                      <div>
                         <b-input-group class="mb-3">
                          <b-form-input
                            v-model="optimization.endDate" 
                            type="text"
                            placeholder="DD/MM/YYYY"
                            autocomplete="off"
                          ></b-form-input>
                          <b-input-group-append>
                            <b-form-datepicker 
                              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                              today-button
                              locale="en-US"                          
                              button-only
                              top
                              reset-button
                              close-button
                              @input="(v) => optimization.endDate = parseDatePicker(v)"
                              class="mb-2">
                            </b-form-datepicker>
                          </b-input-group-append>
                        </b-input-group>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mt-4">
                  <table class="table table-striped table-sm table-hover">
                    <thead >
                      <tr>
                        <th style="width:60px;max-width:60px;">
                           <input type="checkbox" class="m-2" v-model="checkedEditedComposition" /> 
                        </th>
                        <th class="">Index name</th>
                      
                        <th v-for="p in (selectedPortfolioSolution || {}).positionParameters || []" :key="p.id">
                          <label>
                            {{p.title}}
                            <span v-if="p.type !== 'Index'" class="ml-1 mr-2">
                               (%)
                            </span>
                            <span v-b-tooltip.hover.html="getPositionParameterTooltip(selectedPortfolioSolution.name, p.name)" triggers="hover click">
                              <i class="mdi mdi-information-variant" style="background-color:blue;border-radius:50%"/>
                            </span>
                        </label> 
                        </th>
                      
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(i, $ccnIdex) in composeIndexItems" :key="$ccnIdex">
                        <td  style="width:60px;max-width:60px;">
                          <input type="checkbox" class="m-2" v-model="i.checked" @update="$forceUpdate()"/>
                        </td>
                        <td class="">
                          {{i.displayName}}
                          <small v-if="i.minDate" class="d-block">
                            <span class="font-bold">Start Date: {{i.minDate | moment('LL')}}</span>
                          </small>
                        </td>
                        <td v-for="p in (selectedPortfolioSolution || {}).positionParameters || []" :key="p.id">
                          <div class="input-group">
                            <div class="input-group-append">
                              <div class="btn-group">
                                <button type="button" class="btn btn-secondary btn-sm px-3"
                                  @click="toggleInputParamtersForm(($ccnIdex + '+' + p.id))"
                                >
                                  <i class="fa fa-copy "></i>
                                </button>
                                <div 
                                  class="dropdown-menu dropdown-menu-end border show" 
                                  v-click-outside="inputParametersFormOutsideClickConfig"
                                  v-if="inputParamtersFormId === ($ccnIdex + '+' + p.id)"
                                >
                                  <a href="javascript:void(0)" class="dropdown-item" style="cursor: pointer;" 
                                    @click="copyParameterToSelected($ccnIdex, i.id, p)"
                                  >
                                    Clone to selected
                                  </a>
                                  <a href="javascript:void(0)" class="dropdown-item" style="cursor: pointer;"
                                    @click="copyParameterToAll($ccnIdex, i.id, p)"
                                  >
                                    Clone to all
                                  </a>
                                </div>
                              </div>
                            </div> 
                            <b-form-input 
                              v-if="p.type !== 'Index'" 
                              type="number" 
                              :formatter="formatPercentageInput"
                              :step="(p.type !== 'Float' ? 1 : 0.1)" 
                              :min="(p.min || 0) * 100" 
                              :max="(p.max || 1) * 100"
                              :value="formatToPercentage(optimization.solution.values[p.id + i.id])"
                              @update="$forceUpdate()"
                              @change="(value) => setValueFromPercentage(p.id + i.id, value)"
                            
                            />
                          
                            <b-form-input
                              v-if="p.type === 'Index'" 
                              type="text" 
                              @update="$forceUpdate()"
                              v-model.number="optimization.solution.values[p.id + i.id]" 
                            />
                          </div>
                        </td>
                        
                      </tr>
                    </tbody>
                  </table>
                  <!-- <div v-for="i in composeIndexItems" :key="i.id">
                   
                    <div style="background-color: #f1f5f7;display: block;color: #343a40;font-weight: 500;padding: 8px 16px;">
                      <span class="mr-2"> {{i.displayName}}</span>
                     
                      <small v-if="i.minDate" class="d-block">
                        <span class="font-bold">Start Date: {{i.minDate | moment('LL')}}</span>
                      </small>
                    </div>
                    <div class="row  no-gutters p-2"  v-for="p in (selectedPortfolioSolution || {}).positionParameters || []" :key="p.id">
                      <div class="col-5">
                        <label  class="small">
                            {{p.title}}
                            <span v-if="p.type !== 'Index'" class="ml-1 mr-2">
                               (%)
                            </span>
                            <span v-b-tooltip.hover.html="getPositionParameterTooltip(selectedPortfolioSolution.name, p.name)" triggers="hover click">
                              <i class="mdi mdi-information-variant" style="background-color:#eff2f7;border-radius:50%"/>
                            </span>
                        </label> 
                      </div>
                      <div class="col-7">
                       
                      
                        <b-form-input 
                          v-if="p.type !== 'Index'" 
                          type="number" 
                          :formatter="formatPercentageInput"
                          :step="(p.type !== 'Float' ? 1 : 0.1)" 
                          :min="(p.min || 0) * 100" 
                          :max="(p.max || 1) * 100"
                          :value="formatToPercentage(optimization.solution.values[p.id + i.id])"
                          @change="(value) => setValueFromPercentage(p.id + i.id, value)"
                         
                        />
                       
                        <b-form-input
                          v-if="p.type === 'Index'" 
                          type="text" 
                          v-model.number="optimization.solution.values[p.id + i.id]" 
                        />
                      </div>
                    </div>
                  </div> -->
                  
                </div> 
             
            </div>
            <div class="card-body" v-if="composeIndexStep === 2"  style="overflow: auto">
              
                <b-alert
                  variant="danger"
                  class="mt-3"
                  v-if="errors.length > 0"
                  show
                  dismissible
                >
                  <ul class="list-unstyled">
                    <li v-for="e in errors" :key="e">{{e}}</li>
                  </ul>
                </b-alert>
                <div class="form-group">
                  <multiselect 
                    v-model="composedIndexBasicInfo.tags" 
                    placeholder="Tags"
                    :options="allTags" 
                    :taggable="true"
                    @tag="addTag"
                    :multiple="true">
                  </multiselect>
                </div>
                <div class="form-group">
                  <label v-if="!composedIndexBasicInfo.isTemplate">
                    Index Name [* required field]
                  </label>
                  <label v-if="composedIndexBasicInfo.isTemplate">
                    Template Name [* required field]
                  </label>
                  <div>
                    <input type="text" class="form-control" v-model="composedIndexBasicInfo.name" :disabled="composedIndexSubmiting"/>
                  </div>
                </div>
                <div class="form-group">
                  
                  <input id="isTemplate" type="checkbox" class="mr-2" v-model="composedIndexBasicInfo.isTemplate"/>
                  <label for="isTemplate">
                    Template
                  </label>
                </div>
                
                <div class="form-group"  v-if="!composedIndexBasicInfo.isTemplate">
                  <label>
                    Description
                  </label>
                  <div>
                    <textarea type="text" class="form-control" v-model="composedIndexBasicInfo.description" :disabled="composedIndexSubmiting">
                    </textarea>
                  </div>
                </div>

                <div class="form-group" v-if="isAdmin && !composedIndexBasicInfo.isTemplate">
                  <input id="publicIndex" type="checkbox" class="mr-2" v-model="publicIndex"/>
                  <label for="publicIndex">
                    Public Index
                  </label>
                </div>

                <div class="form-group" v-if="!composedIndexBasicInfo.isTemplate">
                  <label>
                    Asset Class
                  </label>
                  <select class="form-control" v-model="composedIndexBasicInfo.assetClass">
                    <option :value="assetClasses.bonds">Bonds</option>
                    <option :value="assetClasses.commodity">Commodity</option>
                    <option :value="assetClasses.cryptoCurrency">Crypto Currency</option>
                    <option :value="assetClasses.etf">ETF</option>
                    <option :value="assetClasses.equities">Equities</option>
                    <option :value="assetClasses.fx">FX</option>
                    <option :value="assetClasses.fixedIncome">Fixed Income</option>
                    <option :value="assetClasses.futures">Futures</option>
                    <option :value="assetClasses.futuresCommodities">Futures Commodities</option>
                    <option :value="assetClasses.futuresRates">Futures Rates</option>
                    <option :value="assetClasses.moneyMarket">Money Market</option>
                  </select>
                </div>

                <div class="form-group" v-if="!composedIndexBasicInfo.isTemplate">
                  <label>
                    Benchmark
                  </label>
                  <select class="form-control" v-model="composedIndexBasicInfo.benchMark">
                    <option value="">-None-</option>
                    <option value="SPDR S&P 500 ETF Trust">S&P 500</option>
                    <option value="FTSE 100 Total Return Index">FTSE 100</option>
                    <option value="OMX S30 Total Return Index">OMX S30</option>
                    <option value="STOXX 600 Net Total Return Index">STOXX 600</option>
                    <option value="Hang Seng Index Total Return">Hang Seng</option>
                    <option value="CSI 300 Total Return Index">CSI 300</option>
                    <option value="MSCI ACWI Net Total Return USD Index">MSCI ACWI</option>
                    <!-- Add these in the future, if update their data -->
                    <!-- <option value="NASDAQ-100 Total Return Index">Nasdaq</option>
                    <option value="Russell 3000 Total Return Index">Russell 3000</option>
                    <option value="Dow Jones Industrial Average Total Return Index">DOW</option>
                    <option value="EURO STOXX 50 Net Return">STOXX 50</option>
                    <option value="Nikkei 225 Total Return Index">Nikkei 225</option> -->
                  </select>
                </div>
            </div>

            <div class="buttons">
              <div class="row">
                <div class="col">
                  <div class="btn-group float-left" >
                    <button type="button"  class="btn btn-outline-secondary  mb-2" @click="cancelComposeIndex">Cancel</button>
                  </div>
                  <div class="btn-group float-right" v-if="composeIndexStep == 0">
                    <button type="button"   class="btn btn-primary mb-2" @click="selectPortfolioSolution" :disabled="composeIndexItems.length == 0"> Next <i class="fa fa-chevron-right ml-1"></i></button>
                  </div>
                  <div class="btn-group float-right" v-if="composeIndexStep == 1">
                  <button type="button" :disabled="!selectedPortfolioSolution"  class="btn btn-primary mb-2" @click="inputComposedIndexName"> Next <i class="fa fa-chevron-right ml-1"></i></button>
                  </div>
                  <div class="btn-group float-right" v-if="composeIndexStep == 2">
                    <button type="button"  class="btn btn-success mb-2" @click="submitComposedIndex" :disabled="!composedIndexBasicInfo.name || composedIndexSubmiting"> 
                      <i class="fa fa-save mr-1" v-if="!composedIndexSubmiting"></i>
                      <i class="fa fa-spin fa-spinner mr-1"  v-if="composedIndexSubmiting"></i>
                      Create
                    </button>
                  </div>
                  <div class="btn-group float-right mr-4" v-if="composeIndexStep > 0">
                    <button type="button"  class="btn btn-secondary mb-2" @click="backComposeIndex()" :disabled="composedIndexSubmiting" > 
                      <i class="fa fa-chevron-left mr-1"></i> Back 
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- end card-body -->
        </div>
      </div>

      <div class="mc" :class="{'with-lc':screening.enabled || composeIndexEnabled, 'hidden': composeIndexEnabled && composeIndexStep > 0}">
        <div class="card cardc8">
          <div class="card-content">
            <div class="card-body">
              <div>
                <div class="row mb-2">
                  <div class="col-md-4 text-truncate no-wrap">
                    <div>
                      <b-form-checkbox v-model="criteria.normalisedPerformance" switch class="mr-1" style="display:inline-block;">
                      </b-form-checkbox>{{$t("pages.indexView.normalisedPerformance")}}
                    </div>
                    <div v-if="viewMode === 'grid'">
                      <b-form-checkbox 
                        v-model="mainChartYAxisLogarithmicType" switch class="mr-1" 
                        style="display:inline-block;">
            
                      </b-form-checkbox>{{$t("pages.indexView.yAxisLogarithmic")}}
                    </div>
                   
                  </div>

                  <div class="col-md-8 text-right">
                    
                    <div class="btn-group mr-2 mb-2 mb-sm-0">
                      <button type="button" class="btn btn-outline" :class="{'btn-outline-primary': viewMode === 'list'}" @click="showList()">
                        <i class="fa fa-list"></i>
                      </button>
                    </div>
                    <div class="btn-group mr-4 mb-2 mb-sm-0">
                      <button type="button" class="btn btn-outline " :class="{'btn-outline-primary': viewMode === 'grid'}" @click="showGrid()">
                        <i class="fa fa-th"></i>
                        </button>
                    </div> 
                    <div class="btn-group mr-2 mb-2 mb-sm-0">
                    
                      <b-dropdown left variant="white">
                        <template v-slot:button-content>
                          <i class="fa fa-columns m-0 text-muted font-size-20"></i>
                        </template>
                        <div class="dropdown-item bg-white text-body">
                          <div class="form-group"><input type="checkbox" v-model="statColumns.indexType.visible" id="stateColumns_indexType"/>
                            <label for="stateColumns_indexType" class="ml-2">{{$t('tables.indices.columns.indexType')}}</label>
                          </div>
                          <div class="form-group"><input type="checkbox" v-model="statColumns.indexSubType.visible" id="stateColumns_indexSubType"/>
                            <label for="stateColumns_indexSubType" class="ml-2">Index Sub Type</label>
                          </div>
                          <div class="form-group"><input type="checkbox" v-model="statColumns.market.visible" id="stateColumns_market"/>
                            <label for="stateColumns_market" class="ml-2">{{$t('tables.indices.columns.market')}}</label>
                          </div>
                          <div class="form-group"><input type="checkbox" v-model="statColumns.provider.visible" id="stateColumns_provider"/>
                            <label for="stateColumns_provider" class="ml-2">{{$t('tables.indices.columns.provider')}}</label>
                          </div>
                          <div class="form-group"><input type="checkbox" v-model="statColumns.assetClass.visible" id="stateColumns_assetClass"/>
                            <label for="stateColumns_assetClass" class="ml-2">{{$t('tables.indices.columns.assetClass')}}</label>
                          </div>
                          <div class="form-group"><input type="checkbox" v-model="statColumns.instrumentType.visible" id="stateColumns_instrumentType"/>
                            <label for="stateColumns_instrumentType" class="ml-2">{{$t('tables.indices.columns.instrumentType')}}</label>
                          </div>
                          <div class="form-group"><input type="checkbox" v-model="statColumns.rebalanceFrequency.visible" id="stateColumns_rebalanceFrequency"/>
                            <label for="stateColumns_rebalanceFrequency" class="ml-2">{{$t('tables.indices.columns.rebalanceFrequency')}}</label>
                          </div>
                          <div class="form-group"><input type="checkbox" v-model="statColumns.ytd.visible" id="stateColumns_ytd"/>
                            <label for="stateColumns_ytd" class="ml-2">{{$t('tables.indices.columns.ytd')}}</label>
                          </div>
                          <div class="form-group"><input type="checkbox" v-model="statColumns.mtd.visible" id="stateColumns_mtd"/>
                            <label for="stateColumns_mtd" class="ml-2">{{$t('tables.indices.columns.mtd')}}</label>
                          </div>
                          <div class="form-group"><input type="checkbox" v-model="statColumns.statUpdatedAt.visible" id="stateColumns_statUpdatedAt"/>
                            <label for="stateColumns_statUpdatedAt" class="ml-2">Updated At</label>
                          </div>
                          <div class="form-group"><input type="checkbox" v-model="statColumns.annualisedReturn.visible" id="stateColumns_annualisedReturn"/>
                            <label for="stateColumns_annualisedReturn" class="ml-2">{{$t('tables.indices.columns.annualisedReturn')}}</label>
                          </div>
                          <div class="form-group"><input type="checkbox" v-model="statColumns.realisedVolatility.visible" id="stateColumns_realisedVolatility"/>
                            <label for="stateColumns_realisedVolatility" class="ml-2">{{$t('tables.indices.columns.realisedVolatility')}}</label>
                          </div>
                          <div class="form-group"><input type="checkbox" v-model="statColumns.downsideVolatility.visible" id="stateColumns_downsideVolatility"/>
                            <label for="stateColumns_downsideVolatility" class="ml-2">{{$t('tables.indices.columns.downsideVolatility')}}</label>
                          </div>
                          <div class="form-group"><input type="checkbox" v-model="statColumns.maxDrawdown.visible" id="stateColumns_maxDrawdown"/>
                            <label for="stateColumns_maxDrawdown" class="ml-2">{{$t('tables.indices.columns.maxDrawdown')}}</label>
                          </div>
                          <div class="form-group"><input type="checkbox" v-model="statColumns.winningMonths.visible" id="stateColumns_winningMonths"/>
                            <label for="stateColumns_winningMonths" class="ml-2">{{$t('tables.indices.columns.winningMonths')}}</label>
                          </div>
                          <div class="form-group"><input type="checkbox" v-model="statColumns.sharpeRatio.visible" id="stateColumns_sharpeRatio"/>
                            <label for="stateColumns_sharpeRatio" class="ml-2">{{$t('tables.indices.columns.sharpeRatio')}}</label>
                          </div>
                          <div class="form-group"><input type="checkbox" v-model="statColumns.sortinoRatio.visible" id="stateColumns_sortinoRatio"/>
                            <label for="stateColumns_sortinoRatio" class="ml-2">{{$t('tables.indices.columns.sortinoRatio')}}</label>
                          </div>
                          <div class="form-group"><input type="checkbox" v-model="statColumns.calmarRatio.visible" id="stateColumns_calmarRatio"/>
                            <label for="stateColumns_calmarRatio" class="ml-2">{{$t('tables.indices.columns.calmarRatio')}}</label>
                          </div>
                          <div class="form-group"><input type="checkbox" v-model="statColumns.externalId.visible" id="stateColumns_externalId"/>
                            <label for="stateColumns_externalId" class="ml-2">{{$t('tables.indices.columns.externalId')}}</label>
                          </div>
                        </div>
                      </b-dropdown>

                      <b-dropdown left variant="white" ref="sortByDropdown">
                        <template v-slot:button-content>
                          <b class="mr-2" style="">{{fieldsMap[criteria.sortBy]}}</b>
                          <i class="fas text-muted fa-sort-alpha-up mr-2" v-if=" criteria.sortByDir === 'asc'"/>
                          <i class="fas text-muted fa-sort-alpha-down mr-2" v-if=" criteria.sortByDir !== 'asc'"/>
                          <i class="fas fa-caret-down"/>
                        </template>
                        <b-dropdown-form>
                          <select class="form-control " 
                            :disabled="isBusy"
                            :value="criteria.sortBy" 
                            style="width:200px" 
                            @change="($event) => sortByColumn($event.target.value)"
                          >
                            <option value="name">Name</option>
                            <option value="index-type">{{$t('tables.indices.columns.indexType')}}</option>
                            <option value="market">{{$t('tables.indices.columns.market')}}</option>
                            <option value="provider">{{$t('tables.indices.columns.provider')}}</option>
                            <option value="asset-class">{{$t('tables.indices.columns.assetClass')}}</option>
                            <option value="instrument-type">{{$t('tables.indices.columns.instrumentType')}}</option>
                            <option value="rebalance-frequency">{{$t('tables.indices.columns.rebalanceFrequency')}}</option>
                            <option value="ytd">{{$t('tables.indices.columns.ytd')}}</option>
                            <option value="mtd">{{$t('tables.indices.columns.mtd')}}</option>
                            <option value="stat-updated">Updated At</option>
                            <option value="annualised-return">{{$t('tables.indices.columns.annualisedReturn')}}</option>
                            <option value="realised-volatility">{{$t('tables.indices.columns.realisedVolatility')}}</option>
                            <option value="downside-volatility">{{$t('tables.indices.columns.downsideVolatility')}}</option>
                            <option value="max-drawdown">{{$t('tables.indices.columns.maxDrawdown')}}</option>
                            <option value="winning-months">{{$t('tables.indices.columns.winningMonths')}}</option>
                            <option value="sharpe-ratio">{{$t('tables.indices.columns.sharpeRatio')}}</option>
                            <option value="sortino-ratio">{{$t('tables.indices.columns.sortinoRatio')}}</option>
                            <option value="calmar-ratio">{{$t('tables.indices.columns.calmarRatio')}}</option>
                            <!-- <option value="external-id">{{$t('tables.indices.columns.externalId')}}</option> -->
                          </select>
                          <div class="row">
                            <div class="col  mt-2">
                              <button type="button" class="btn btn-block" 
                              :disabled="isBusy"
                              @click="sortByDir('asc')"
                                :class="{' btn-outline-secondary': criteria.sortByDir !== 'asc', ' btn-secondary': criteria.sortByDir === 'asc'}"
                                >
                                <i class="fas fa-sort-alpha-up mr-2"></i>Asc
                              </button>
                            </div>
                            <div class="col mt-2">
                              <button type="button" class="btn btn-outline-secondary btn-block"
                              :disabled="isBusy"
                                @click="sortByDir('desc')"
                              :class="{' btn-outline-secondary': criteria.sortByDir !== 'desc', ' btn-secondary': criteria.sortByDir === 'desc'}"
                                >
                                <i class="fas fa-sort-alpha-down mr-2"></i>Desc
                              </button>
                            </div>
                          </div>
                        </b-dropdown-form>
                        <div class="p-2 pl-4 pr-4">
                          <b-button variant="link" block @click="() => $refs.sortByDropdown.hide(true)">Close</b-button>
                        </div>
                        
                      </b-dropdown>
                    </div>

                    <div class="btn-group mr-2 mb-2 mb-sm-0">
                    </div>
                  </div>
                </div>
                <div class="indices-content" v-if="viewMode === 'grid'">
                  <div style="height:100%; overflow:auto">
                 
                    <div class="row no-gutters" >
                      <div class="col-lg-6">
                        <ul class="list-inline my-3 ecommerce-sortby-list" >
                          <li class="list-inline-item">
                            Legend: 
                          </li>
                          <li class="list-inline-item">
                            <div class="badge badge-warning" >Provider</div>
                          </li>
                        <li class="list-inline-item">
                            <div class="badge badge-primary" >Asset class</div>
                          </li>
                          <li class="list-inline-item">
                            <div class="badge badge-success">Instrument type</div>
                          </li>
                          <li class="list-inline-item">
                            <div class="badge badge-secondary"  >Rebalance frequency</div>
                          </li>
                        </ul>
                       
                      </div>
                      <div class="col-lg-6 text-right">
                        
                      </div>
                    </div>
                  
                    <div class="row no-gutters">
                      <div class="col-lg-4" v-for="item in items" :key="item.id">
                        <div class="product-box" style="padding-bottom:0">
                        
                          <highcharts v-if="item.chartOptions"
                            :constructorType="'stockChart'"
                            :options="item.chartOptions">
                          </highcharts>
                          <ul class="list-inline my-3 ecommerce-sortby-list" style="position: absolute;top: -10px;left: 5px; right:40px;">
                            <li class="list-inline-item">
                              <div class="badge badge-warning" >{{(item.provider || {}).displayName}}</div>
                            </li>
                            <li class="list-inline-item">
                              <div class="badge badge-primary" >{{assetClassMap[item.assetClass || {}]}}</div>
                            </li>
                            <li class="list-inline-item">
                              <div class="badge badge-success">{{instrumentTypeMap[item.instrumentType || {}]}}</div>
                            </li>
                            <li class="list-inline-item">
                              <div class="badge badge-secondary">{{rebalanceFrequencyMap[item.rebalanceFrequency || {}]}}</div>
                            </li>
                            <li class="list-inline-item" v-for="(tag,i) in item.tags" :key="i">
                              <div class="badge badge-light"> <i class="fa fa-tag mr-1"></i> {{tag}}</div>
                            </li>
                          </ul>
                          <div class="text-center">
                            <p class="font-size-12 mb-1" :title="'Index Type: ' + (item.indexType || {}).displayName">Index type: {{(item.indexType || {}).displayName}}</p>
                            <h5 class="font-size-15">
                              <router-link :to="'/indices/' + item.id + '/view'" class="text-dark mr-2">{{item.displayName}}</router-link>
                              <span v-if="subscriptions[item.id]" class="badge badge-success">
                                subscribed
                              </span>
                            </h5>
                            <p class="font-size-12 mt-3 mb-0 text-info" :title="'Market: ' + (item.market || {}).displayName">Market: {{(item.market || {}).displayName}}</p>
                          </div>
                          
                          <table class="mt-4 table table-sm table-hover table-borderless small">
                            <tr v-if="statColumns.ytd.visible || statColumns.mtd.visible">
                              <td>YTD</td>
                              <td class="text-primary"> {{item.performance.ytdFormatted}} <span v-if="item.performance.ytdFormatted">%</span></td>
                              <td>MTD</td>
                              <td class="text-primary"> {{item.performance.mtdFormatted}} <span  v-if="item.performance.mtdFormatted">%</span></td>
                            </tr>
                            <tr v-if="statColumns.annualisedReturn.visible || statColumns.realisedVolatility.visible">
                              <td class="no-wrap">{{$t('tables.indices.columns.annualisedReturn')}}</td>
                              <td class="text-primary font-weight-bold">
                                <span v-if="item.statistic">
                                  {{item.statistic.values.annualisedReturnFormatted}} <span  v-if="item.statistic.values.annualisedReturnFormatted">%</span>
                                </span>
                              </td>
                              <td class="no-wrap">{{$t('tables.indices.columns.realisedVolatility')}}</td>
                              <td class="text-primary font-weight-bold">
                                <span v-if="item.statistic">
                                  {{item.statistic.values.realisedVolatilityFormatted}} <span  v-if="item.statistic.values.realisedVolatilityFormatted">%</span>
                                </span>
                              </td>
                            </tr>
                            <tr v-if="statColumns.downsideVolatility.visible || statColumns.maxDrawdown.visible">
                              <td class="no-wrap">{{$t('tables.indices.columns.downsideVolatility')}}</td>
                              <td class="text-primary font-weight-bold">
                                <span v-if="item.statistic">
                                  {{item.statistic.values.downsideVolatilityFormatted}} <span  v-if="item.statistic.values.downsideVolatilityFormatted">%</span>
                              </span>
                              </td>
                              <td class="no-wrap">
                                {{$t('tables.indices.columns.maxDrawdown')}}
                                </td>
                              <td class="text-primary font-weight-bold">
                                <span v-if="item.statistic">
                                  {{item.statistic.values.maxDrawdownFormatted}} <span  v-if="item.statistic.values.maxDrawdownFormatted">%</span>
                                </span>
                              </td>
                            </tr>
                            <tr v-if="statColumns.winningMonths.visible || statColumns.sharpeRatio.visible">
                              <td class="no-wrap">{{$t('tables.indices.columns.winningMonths')}}</td>
                              <td class="text-primary font-weight-bold">
                                <span v-if="item.statistic">
                                  {{item.statistic.values.winningMonthsFormatted}} <span  v-if="item.statistic.values.winningMonthsFormatted">%</span>
                                </span>
                              </td>
                              <td class="no-wrap">
                                {{$t('tables.indices.columns.sharpeRatio')}}
                                </td>
                              <td class="text-primary font-weight-bold">
                                <span v-if="item.statistic">
                                  {{item.statistic.values.sharpeRatioFormatted}} 
                              </span>
                              </td>
                            </tr>
                            <tr v-if="statColumns.sortinoRatio.visible || statColumns.calmarRatio.visible">
                              <td class="no-wrap">{{$t('tables.indices.columns.sortinoRatio')}}</td>
                              <td class="text-primary font-weight-bold">
                                <span v-if="item.statistic">
                                  {{item.statistic.values.sortinoRatioFormatted}} 
                              </span>
                              </td>
                              <td class="no-wrap">
                                {{$t('tables.indices.columns.calmarRatio')}}
                                </td>
                              <td class="text-primary font-weight-bold">
                                <span v-if="item.statistic">
                                  {{item.statistic.values.calmarRatioFormatted}} 
                              </span>
                              </td>
                            </tr>
                            <tr v-if="statColumns.externalId.visible">
                              <td class="no-wrap">{{$t('tables.indices.columns.externalId')}}</td>
                              <td class="text-primary font-weight-bold">
                                {{(item.systemValues || {}).ExternalId}}
                              </td>
                              <td class="no-wrap">
                                
                              </td>
                              <td class="text-primary font-weight-bold">
                               
                              </td>
                            </tr>
                          </table>
                        
                          <div class=" text-center" style="position: absolute;top: 30%;left:0; right:0; " v-if="composeIndexEnabled && !composeIndexItems.find(a => a.id === item.id)">
                            <button style="opacity:0.8" class="btn  btn-lg btn-primary"  @click="selectComposeIndexPart(item)">Select</button>
                          </div>

                          <div  class=" text-center" style="position: absolute;top: 30%;left:0; right:0; " v-if="(screening.enabled && !screening.items.find(a => a.id === item.id)) && isIndexAllowedForScreening(item)">
                            <button style="opacity:0.8"  class="btn  btn-lg btn-secondary"  @click="addIndexToScreening(item)">Select</button>
                          </div>
                        
                          <div style="position: absolute;top: 0;right: 0;" v-if="!composeIndexEnabled && !screening.enabled">
                          
                            <b-dropdown left variant="white" v-if="isAdmin || item.allowEdit || item.allowDelete">
                              <template v-slot:button-content>
                                <i class="mdi mdi-dots-vertical m-0 text-muted font-size-20"></i>
                              </template>
                              <b-dropdown-item v-if="isAdmin"  @click="uploadFile(item)">{{$t("tables.indices.uploadFile")}}</b-dropdown-item>
                              <b-dropdown-item v-if="item.allowEdit"  :href="'indices/' + item.id + '/edit'">{{$t("tables.indices.edit")}}</b-dropdown-item>
                              <b-dropdown-divider></b-dropdown-divider>
                              <b-dropdown-item v-if="item.allowDelete" @click="confirmDelete(item)">{{$t("common.delete")}}</b-dropdown-item>
                            </b-dropdown>
                          </div>

                          
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              
                <div class="indices-content" v-if="viewMode === 'list'">
                  <div style="max-height:100%; min-height:300px; overflow:auto">
                  
                    <table class="table table-centered mb-0 table-nowrap table-sm" >
                      <thead  style="position: sticky; top: 0;z-index:99;">
                        <tr>
                           <th class="text-left favorites" style="width:50px; min-width:50px;">
                            <span v-if="!composeIndexEnabled && !screening.enabled">
                              <a href="javascript:void(0)" v-if="!showOnlyBookmarks" >
                                <i style="font-size:1.7rem;color:white" class="mdi mdi-star-outline" @click="() => showOnlyBookmarks = !showOnlyBookmarks"/>
                              </a>
                              <a href="javascript:void(0)" v-if="showOnlyBookmarks">
                                <i style="font-size:1.7rem" class="mdi mdi-star text-warning" @click="() => showOnlyBookmarks = !showOnlyBookmarks"/>
                              </a>
                            </span>
                            <span v-if="composeIndexEnabled || screening.enabled"> 
                              <button class="btn  btn-sm btn-c8 revers" v-if="composeIndexEnabled" @click="selectAllComposeIndexPart">
                                <span class="btn-content">
                                  Select All
                                </span>
                              </button>
                              <button class="btn  btn-sm btn-c8 revers" 
                                v-if="screening.enabled" 
                                @click="addAllIndexToScreening"
                              >
                                <span class="btn-content">
                                  Select All
                                </span>
                              </button>
                            </span>
                          </th>
                         
                          <th style="cursor:pointer; min-width:250px;width:300px;" @click="sortBy('name')">
                            <span class="mr-1"> {{$t('tables.indices.columns.name')}}</span>
                          
                            <span class="float-right" v-if="criteria.sortBy === 'name'">
                              <span v-if="criteria.sortByDir === 'asc'">
                                <i class="fa  fas fa-sort-up"></i>
                              </span>
                              <span v-if="criteria.sortByDir === 'desc'">
                                <i class="fa  fas fa-sort-down"></i>
                              </span>
                            </span>
                          </th>
                          <th style="cursor:pointer;min-width: 100px;" @click="sortBy('index-type')" v-if="statColumns.indexType.visible">
                            <span class="mr-1">{{$t('tables.indices.columns.indexType')}}</span>
                            <span class="float-right" v-if="criteria.sortBy === 'index-type'" >
                              <span v-if="criteria.sortByDir === 'asc'">
                                <i class="fa  fas fa-sort-up"></i>
                              </span>
                              <span v-if="criteria.sortByDir === 'desc'">
                                <i class="fa  fas fa-sort-down"></i>
                              </span>
                            </span>
                          </th>
                          <th style="cursor:pointer;min-width: 120px;" @click="sortBy('index-sub-type')" v-if="statColumns.indexSubType.visible">
                            <span class="mr-1">Index Sub Type</span>
                            <span class="float-right" v-if="criteria.sortBy === 'index-sub-type'" >
                              <span v-if="criteria.sortByDir === 'asc'">
                                <i class="fa  fas fa-sort-up"></i>
                              </span>
                              <span v-if="criteria.sortByDir === 'desc'">
                                <i class="fa  fas fa-sort-down"></i>
                              </span>
                            </span>
                          </th>
                          <th style="cursor:pointer;min-width: 80px;" @click="sortBy('market')" v-if="statColumns.market.visible">
                            <span class="mr-1">{{$t('tables.indices.columns.market')}}</span>
                            <span class="float-right" v-if="criteria.sortBy === 'market'" >
                              <span v-if="criteria.sortByDir === 'asc'">
                                <i class="fa  fas fa-sort-up"></i>
                              </span>
                              <span v-if="criteria.sortByDir === 'desc'">
                                <i class="fa  fas fa-sort-down"></i>
                              </span>
                            </span>
                          </th>
                          <th style="cursor:pointer;min-width: 100px;" @click="sortBy('provider')" v-if="statColumns.provider.visible">
                            <span class="mr-1">{{$t('tables.indices.columns.provider')}}</span>
                            <span class="float-right" v-if="criteria.sortBy === 'provider'">
                              <span v-if="criteria.sortByDir === 'asc'">
                                <i class="fa  fas fa-sort-up"></i>
                              </span>
                              <span v-if="criteria.sortByDir === 'desc'">
                                <i class="fa  fas fa-sort-down"></i>
                              </span>
                            </span>
                          </th>
                          <th style="cursor:pointer;min-width: 100px;" @click="sortBy('asset-class')" v-if="statColumns.assetClass.visible">
                            <span class="mr-1">{{$t('tables.indices.columns.assetClass')}}</span>
                            <span class="float-right" v-if="criteria.sortBy === 'asset-class'">
                              <span v-if="criteria.sortByDir === 'asc'">
                                <i class="fa  fas fa-sort-up"></i>
                              </span>
                              <span v-if="criteria.sortByDir === 'desc'">
                                <i class="fa  fas fa-sort-down"></i>
                              </span>
                            </span>
                          </th>
                          <th style="cursor:pointer;min-width: 100px;white-space: normal;" @click="sortBy('instrument-type')" v-if="statColumns.instrumentType.visible">
                            <span class="mr-1">{{$t('tables.indices.columns.instrumentType')}}</span>
                            <span class="float-right" v-if="criteria.sortBy === 'instrument-type'">
                              <span v-if="criteria.sortByDir === 'asc'">
                                <i class="fa  fas fa-sort-up"></i>
                              </span>
                              <span v-if="criteria.sortByDir === 'desc'">
                                <i class="fa  fas fa-sort-down"></i>
                              </span>
                            </span>
                          </th>
                          <th style="cursor:pointer;min-width: 100px;white-space: normal;" @click="sortBy('rebalance-frequency')" v-if="statColumns.rebalanceFrequency.visible">
                            <span class="mr-1">{{$t('tables.indices.columns.rebalanceFrequency')}}</span>
                            <span class="float-right" v-if="criteria.sortBy === 'rebalance-frequency'">
                              <span v-if="criteria.sortByDir === 'asc'">
                                <i class="fa  fas fa-sort-up"></i>
                              </span>
                              <span v-if="criteria.sortByDir === 'desc'">
                                <i class="fa  fas fa-sort-down"></i>
                              </span>
                            </span>
                          </th>
                          <th style="cursor:pointer;" @click="sortBy('ytd')" v-if="statColumns.ytd.visible">
                            <span class="mr-1">{{$t('tables.indices.columns.ytd')}}</span>
                            <span class="float-right" v-if="criteria.sortBy === 'ytd'" >
                              <span v-if="criteria.sortByDir === 'asc'">
                                <i class="fa  fas fa-sort-up"></i>
                              </span>
                              <span v-if="criteria.sortByDir === 'desc'">
                                <i class="fa  fas fa-sort-down"></i>
                              </span>
                            </span>
                          </th>
                          <th style="cursor:pointer;" @click="sortBy('mtd')" v-if="statColumns.mtd.visible">
                            <span class="mr-1">{{$t('tables.indices.columns.mtd')}}</span>
                            <span class="float-right" v-if="criteria.sortBy === 'mtd'" >
                              <span v-if="criteria.sortByDir === 'asc'">
                                <i class="fa  fas fa-sort-up"></i>
                              </span>
                              <span v-if="criteria.sortByDir === 'desc'">
                                <i class="fa  fas fa-sort-down"></i>
                              </span>
                            </span>
                          </th>
                           <th style="cursor:pointer;" @click="sortBy('stat-updated')" v-if="statColumns.statUpdatedAt.visible">
                            <span class="mr-1">Updated At</span>
                            <span class="float-right" v-if="criteria.sortBy === 'stat-updated'" >
                              <span v-if="criteria.sortByDir === 'asc'">
                                <i class="fa  fas fa-sort-up"></i>
                              </span>
                              <span v-if="criteria.sortByDir === 'desc'">
                                <i class="fa  fas fa-sort-down"></i>
                              </span>
                            </span>
                          </th>
                          <th style="cursor:pointer;max-width:90px;white-space: normal;" @click="sortBy('annualised-return')" v-if="statColumns.annualisedReturn.visible">
                            <span class="mr-1">{{$t('tables.indices.columns.annualisedReturn')}}</span>
                            <span class="float-right" v-if="criteria.sortBy === 'annualised-return'" >
                              <span v-if="criteria.sortByDir === 'asc'">
                                <i class="fa  fas fa-sort-up"></i>
                              </span>
                              <span v-if="criteria.sortByDir === 'desc'">
                                <i class="fa  fas fa-sort-down"></i>
                              </span>
                            </span>
                          </th>
                          <th style="cursor:pointer;max-width:100px;min-width:100px;white-space: normal;" @click="sortBy('realised-volatility')" v-if="statColumns.realisedVolatility.visible">
                            <span class="mr-1">{{$t('tables.indices.columns.realisedVolatility')}}</span>
                            <span class="float-right" v-if="criteria.sortBy === 'realised-volatility'" >
                              <span v-if="criteria.sortByDir === 'asc'">
                                <i class="fa  fas fa-sort-up"></i>
                              </span>
                              <span v-if="criteria.sortByDir === 'desc'">
                                <i class="fa  fas fa-sort-down"></i>
                              </span>
                            </span>
                          </th>
                          <th  style="cursor:pointer;max-width:110px;min-width:110px;white-space: normal;;"  @click="sortBy('downside-volatility')" v-if="statColumns.downsideVolatility.visible">
                            <span class="mr-1">{{$t('tables.indices.columns.downsideVolatility')}}</span>
                            <span class="float-right" v-if="criteria.sortBy === 'downside-volatility'" >
                              <span v-if="criteria.sortByDir === 'asc'">
                                <i class="fa  fas fa-sort-up"></i>
                              </span>
                              <span v-if="criteria.sortByDir === 'desc'">
                                <i class="fa  fas fa-sort-down"></i>
                              </span>
                            </span>
                          </th>
                          <th style="cursor:pointer;max-width:110px;min-width:110px;white-space: normal;" @click="sortBy('max-drawdown')" v-if="statColumns.maxDrawdown.visible">
                            <span class="mr-1">{{$t('tables.indices.columns.maxDrawdown')}}</span>
                            <span class="float-right" v-if="criteria.sortBy === 'max-drawdown'" >
                              <span v-if="criteria.sortByDir === 'asc'">
                                <i class="fa  fas fa-sort-up"></i>
                              </span>
                              <span v-if="criteria.sortByDir === 'desc'">
                                <i class="fa  fas fa-sort-down"></i>
                              </span>
                            </span>
                          </th>
                          <th style="cursor:pointer;max-width:110px;min-width:110px;white-space: normal;" @click="sortBy('winning-months')" v-if="statColumns.winningMonths.visible">
                            <span class="mr-1">{{$t('tables.indices.columns.winningMonths')}}</span>
                            <span class="float-right" v-if="criteria.sortBy === 'winning-months'" >
                              <span v-if="criteria.sortByDir === 'asc'">
                                <i class="fa  fas fa-sort-up"></i>
                              </span>
                              <span v-if="criteria.sortByDir === 'desc'">
                                <i class="fa  fas fa-sort-down"></i>
                              </span>
                            </span>
                          </th>
                          <th style="cursor:pointer;max-width:90px;min-width:90px;white-space: normal;" @click="sortBy('sharpe-ratio')" v-if="statColumns.sharpeRatio.visible">
                            <span class="mr-1">{{$t('tables.indices.columns.sharpeRatio')}}</span>
                            <span class="float-right" v-if="criteria.sortBy === 'sharpe-ratio'" >
                              <span v-if="criteria.sortByDir === 'asc'">
                                <i class="fa  fas fa-sort-up"></i>
                              </span>
                              <span v-if="criteria.sortByDir === 'desc'">
                                <i class="fa  fas fa-sort-down"></i>
                              </span>
                            </span>
                          </th>
                          <th style="cursor:pointer;max-width:90px;min-width:90px;white-space: normal;" @click="sortBy('sortino-ratio')" v-if="statColumns.sortinoRatio.visible">
                            <span class="mr-1">{{$t('tables.indices.columns.sortinoRatio')}}</span>
                            <span class="float-right" v-if="criteria.sortBy === 'sortino-ratio'" >
                              <span v-if="criteria.sortByDir === 'asc'">
                                <i class="fa  fas fa-sort-up"></i>
                              </span>
                              <span v-if="criteria.sortByDir === 'desc'">
                                <i class="fa  fas fa-sort-down"></i>
                              </span>
                            </span>
                          </th>
                          <th style="cursor:pointer;max-width:90px;min-width:90px;white-space: normal;" @click="sortBy('calmar-ratio')" v-if="statColumns.calmarRatio.visible">
                            <span class="mr-1">{{$t('tables.indices.columns.calmarRatio')}}</span>
                            <span class="float-right" v-if="criteria.sortBy === 'calmar-ratio'" >
                              <span v-if="criteria.sortByDir === 'asc'">
                                <i class="fa  fas fa-sort-up"></i>
                              </span>
                              <span v-if="criteria.sortByDir === 'desc'">
                                <i class="fa  fas fa-sort-down"></i>
                              </span>
                            </span>
                          </th>
                          <th style="cursor:pointer;max-width:90px;min-width:90px;white-space: normal;" v-if="statColumns.externalId.visible">
                            <span class="mr-1">{{$t('tables.indices.columns.externalId')}}</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in filteredItems" :key="item.id">
                          <td class="text-left favorites" style="width:50px; min-width:50px;">
                            <span v-if="!composeIndexEnabled && !screening.enabled">
                              <a href="javascript:void(0)" v-if="!bookmarks[item.id]" @click="addBookmark(item.id)" ><i style="font-size:1.7rem" class="mdi mdi-star-outline text-muted "/></a>
                              <a href="javascript:void(0)" v-if="bookmarks[item.id]" @click="removeBookmark(item.id)"><i style="font-size:1.7rem" class="mdi mdi-star text-warning"/></a>
                            </span>
                            <span v-if="composeIndexEnabled || screening.enabled"> 
                              <button class="btn  btn-sm btn-c8" v-if="composeIndexEnabled && !composeIndexItems.find(a => a.id === item.id)" @click="selectComposeIndexPart(item)">
                                <span class="btn-content">
                                  Select
                                </span>
                              </button>
                              <button class="btn  btn-sm btn-c8" 
                                v-if="(screening.enabled && !screening.items.find(a => a.id === item.id)) && isIndexAllowedForScreening(item)" 
                                @click="addIndexToScreening(item)"
                              >
                               <span class="btn-content">
                                  Select
                                </span>
                              </button>
                            </span>
                            <b-dropdown  variant="white" size="sm" v-if="isAdmin || item.allowEdit || item.allowDelete">
                              <template v-slot:button-content>
                                <i class="mdi mdi-dots-vertical m-0 text-muted font-size-20"></i>
                              </template>
                              <b-dropdown-item v-if="isAdmin" @click="uploadFile(item)">{{$t("tables.indices.uploadFile")}}</b-dropdown-item>
                              <b-dropdown-item v-if="item.allowEdit" :href="'indices/' + item.id + '/edit'">{{$t("tables.indices.edit")}}</b-dropdown-item>
                              <b-dropdown-divider></b-dropdown-divider>
                              <b-dropdown-item v-if="item.allowDelete" @click="confirmDelete(item)">{{$t("common.delete")}}</b-dropdown-item>
                            </b-dropdown>
                          </td>
                         
                          <td style="min-width:250px;width:300px;" >
                            <strong style="white-space: initial;" class="mr-2">
                              <router-link :to="'indices/' + item.id + '/view'" class="text-dark">{{item.displayName}}</router-link>
                            </strong>
                            <span class="badge badge-light mr-2" v-for="(tag,i) in item.tags" :key="i">
                             <i class="fa fa-tag mr-1"></i> {{tag}}
                            </span>
                            <span v-if="subscriptions[item.id]" class="badge badge-success">
                                subscribed
                              </span>
                            <div v-if="item.optimization && item.optimization.solutionTitle">
                              <small class="text-muted">
                                Solution: {{item.optimization.solutionTitle}}
                              </small>
                            </div>
                          </td>
                          <td v-if="statColumns.indexType.visible">{{(item.indexType || {}).displayName}}</td>
                          <td v-if="statColumns.indexSubType.visible">{{(item.indexSubType || {}).displayName}}</td>
                          <td v-if="statColumns.market.visible">{{(item.market || {}).displayName}}</td>
                          <td v-if="statColumns.provider.visible">{{(item.provider || {}).displayName}}</td>
                          <td v-if="statColumns.assetClass.visible">{{assetClassMap[item.assetClass || {}]}}</td>
                          <td v-if="statColumns.instrumentType.visible">{{instrumentTypeMap[item.instrumentType || {}]}}</td>
                          <td v-if="statColumns.rebalanceFrequency.visible">{{rebalanceFrequencyMap[item.rebalanceFrequency || {}]}}</td>
                        
                          <td v-if="statColumns.ytd.visible">
                            {{item.performance.ytdFormatted}} <span v-if="item.performance.ytdFormatted">%</span>
                          </td>
                          <td v-if="statColumns.mtd.visible">
                            {{item.performance.mtdFormatted}} <span  v-if="item.performance.mtdFormatted">%</span>
                          </td>
                          <td v-if="statColumns.statUpdatedAt.visible">
                            {{item.statUpdatedAt | moment('DD/MM/YYYY')}} <span  v-if="item.statUpdatedAt"></span>
                          </td>
                          <td v-if="statColumns.annualisedReturn.visible">
                          <span v-if="item.statistic">
                              {{item.statistic.values.annualisedReturnFormatted}} <span  v-if="item.statistic.values.annualisedReturnFormatted">%</span>
                          </span>
                          </td>
                          <td v-if="statColumns.realisedVolatility.visible">
                          <span v-if="item.statistic">
                              {{item.statistic.values.realisedVolatilityFormatted}} <span  v-if="item.statistic.values.realisedVolatilityFormatted">%</span>
                          </span>
                          </td>
                          <td v-if="statColumns.downsideVolatility.visible">
                          <span v-if="item.statistic">
                              {{item.statistic.values.downsideVolatilityFormatted}} <span  v-if="item.statistic.values.downsideVolatilityFormatted">%</span>
                          </span>
                          </td>
                        
                          <td v-if="statColumns.maxDrawdown.visible">
                          <span v-if="item.statistic">
                              {{item.statistic.values.maxDrawdownFormatted}} <span  v-if="item.statistic.values.maxDrawdownFormatted">%</span>
                          </span>
                          </td>
                          <td v-if="statColumns.winningMonths.visible">
                          <span v-if="item.statistic">
                              {{item.statistic.values.winningMonthsFormatted}} <span  v-if="item.statistic.values.winningMonthsFormatted">%</span>
                          </span>
                          </td>
                          <td v-if="statColumns.sharpeRatio.visible">
                          <span v-if="item.statistic">
                              {{item.statistic.values.sharpeRatioFormatted}} 
                          </span>
                          </td>
                          <td v-if="statColumns.sortinoRatio.visible">
                          <span v-if="item.statistic">
                              {{item.statistic.values.sortinoRatioFormatted}} 
                          </span>
                          </td>
                          <td v-if="statColumns.calmarRatio.visible">
                            <span v-if="item.statistic">
                                {{item.statistic.values.calmarRatioFormatted}} 
                            </span>
                          </td>
                          <td v-if="statColumns.externalId.visible">
                            {{(item.systemValues || {}).ExternalId}}
                          </td>
                        </tr>
                        
                      </tbody>
                    </table>
                 
                  </div>

                </div>   
              </div>
            </div>
            <div class="indices-table-footer">
              <div class="">
                <div class="d-flex justify-content-md-between align-items-md-center">
                  <div >Total: {{totalItems}}</div>
                  <div >
                    <select class="form-control input-sm" v-model="perPage">
                      <option :value="20">20 records</option>
                      <option :value="50">50 records</option>
                      <option :value="100">100 records</option>  
                    </select>
                  </div>
                  <div >
                    <div class="text-md-right float-xl-right mt-2 ">
                    
                      <b-pagination v-if="!initializing"
                        v-model="currentPage"
                        :total-rows="totalItems"
                        :per-page="perPage"
                        :limit="3"
                      ></b-pagination>
                    </div>
                  </div>
              

                </div>
              </div>
              
            </div>
            <div v-if="isBusy" style="position:absolute;left:0; top:0; bottom:0; right:0;background:white;opacity:0.5;"></div>
          </div>
        </div>
      </div>
    
    </div>
    
   
     <!-- Modal -->
    <b-modal
      id="modal-1"
      v-model="uploadVisible"
      :title="$t('pages.indices.uploadFile')"
      title-class="text-dark font-18"
      hide-footer
    >
      
         <div class="form-group">
          
          <input type="file" :ref="'file'" class="form-control" v-on:change="handleFileUpload()"/>
        </div>
        
        <div>
          <b-button class="ml-1 float-left" @click="hideUploadFile">Close</b-button>
          <!-- <button type="submit" class="btn btn-success float-right" :disabled="userSaving">
            <i class="fa fa-spin fa-spinner" v-if="userSaving"></i>
            Сохранить
          </button> -->
        </div>
     
    </b-modal>
    <!-- end modal -->
  </Layout>
</template>