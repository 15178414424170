<script>
import simplebar from "simplebar-vue";
import http from '@/oauth-client'
import Multiselect from "vue-multiselect";

export default {
  components: {
    simplebar,
    Multiselect
  },
  props: {
    applyButtonTitle: {
      type: String,
      required: false,
      default: 'Apply'
    },
    resetButtonNoAction:{
      type: Boolean,
      required: false,
      default: false
    },
    persistent: {
      type: Boolean,
      required: false,
      default: true
    },
    values: {
      type: Object,
      required: true,
    },
    criteria: {
      type: Object,
      required: false
    },
    noCard:{
      type: Boolean,
      required: false,
    }
  },
  created() {
     this.filters.push({
      nameAll: 'Select All',
      nameNotAll: 'Reset',
        func(elem) {
          return elem.selected;
        }
    });
    this.p_criteria = this.criteria ? this.criteria : this.defaultCriteria();
    this.load();
  },
  watch: { 
    criteria: function(newVal) { 
       this.p_criteria = newVal ? newVal : this.defaultCriteria();
    }
  },
  data() {
    return {
      instrumentType: true,
      isReady: false,
      filters:[],
      p_criteria: this.defaultCriteria(),
      allTags: []
    };
  },
  computed: {
    providers() {
      if(this.p_criteria.instrumentType.length === 0) return this.values.provider;

      return this.values.provider.filter(x => {
        const o = this.values.providerInstrumentTypes[x.value];
        if(!o) return false;
      
        return this.p_criteria.instrumentType.find(t => o.includes(t)) ? true : false;
      });
    },
    isESGQuickFilterEnabled() {
      const indexType1 = this.values.indexType.find(x => x.text === 'ESG');
      const indexType2 = this.values.indexType.find(x => x.text === 'Market Cap');
      const indexSubType1 = (this.values.indexSubType[indexType2.value] || []).find(x => x.text === 'ESG');
      return this.p_criteria.indexType.find(x => x === indexType1.value)
        && this.p_criteria.indexType.find(x => x === indexType2.value)
        && this.p_criteria.indexSubType.find(x => x === (indexType2.value + '/' +  indexSubType1.value));
    },
    isCommodsQuickFilterEnabled() {
      const assetClass1 = this.values.assetClass.find(x => x.text === 'Commodity');
      const assetClass2 = this.values.assetClass.find(x => x.text === 'Futures Commodities');

       return this.p_criteria.assetClass.find(x => x === assetClass1.value)
        && this.p_criteria.assetClass.find(x => x === assetClass2.value);
    },
    isAlphaQuickFilterEnabled () {
      const indexType1 = this.values.indexType.find(x => x.text === 'ESG');
      const indexType2 = this.values.indexType.find(x => x.text === 'Risk Parity');
      const indexType3 = this.values.indexType.find(x => x.text === 'Risk Premia');
      const indexType4 = this.values.indexType.find(x => x.text === 'Statistical Arbitrage');
      const indexType5 = this.values.indexType.find(x => x.text === 'Trend Following');
      const instrumentType1 = this.values.instrumentType.find(x => x.text === 'Active Index');
      // const instrumentType2 = this.values.instrumentType.find(x => x.text === 'Overlay');

      return this.p_criteria.indexType.find(x => x === indexType1.value)
        && this.p_criteria.indexType.find(x => x === indexType2.value)
        && this.p_criteria.indexType.find(x => x === indexType3.value)
        && this.p_criteria.indexType.find(x => x === indexType4.value)
        && this.p_criteria.indexType.find(x => x === indexType5.value)
        && this.p_criteria.instrumentType.find(x => x === instrumentType1.value);
    },
    isCryptoQuickFilterEnabled () {
      const cryptoCurrencyAsset = this.values.assetClass.find(x => x.text === 'Crypto Currency');
      const instrumentTypeCrypto = this.values.instrumentType.find(x => x.text === 'Crypto');
      return this.p_criteria.assetClass.find(x => x === cryptoCurrencyAsset.value)
       && this.p_criteria.instrumentType.find(x => x === instrumentTypeCrypto.value);
    },
    isThematicQuickFilterEnabled () {
      const indexType2 = this.values.indexType.find(x => x.text === 'Thematic');
      return this.p_criteria.indexType.find(x => x === indexType2.value);
    },
    isGlobalETFsQuickFilterEnabled () {
      const market = this.values.market.find(x => x.text === 'Global');
      const indexType = this.values.indexType.find(x => x.text === 'ETF');
      return this.p_criteria.indexType.find(x => x === indexType.value)
       && this.p_criteria.market.find(x => x === market.value);
    },
    isFilterEnabled() {
      if(this.isStatFiltered) return true;
      if(!this.p_criteria.singleIndices) return true;
      if(!this.p_criteria.compositeIndices) return true;
      if((this.p_criteria.portfolioSolution || []).length > 0) return true;
      if(this.p_criteria.assetClass.length > 0) return true;
      if(this.p_criteria.instrumentType.length > 0) return true;
      if(this.p_criteria.indexType.length > 0) return true;
      if(this.p_criteria.rebalanceFrequency.length > 0) return true;
      if(this.p_criteria.market.length > 0) return true;
      if(this.p_criteria.keyword) return true;
      if(this.p_criteria.provider.length > 0) return true;

      return false;
    },
    isStatFiltered() {
      if(this.p_criteria.mtd.from !== null && this.p_criteria.mtd.from !== undefined) return true;
      if(this.p_criteria.mtd.till !== null && this.p_criteria.mtd.till !== undefined) return true;
      
      if(this.p_criteria.ytd.from !== null && this.p_criteria.ytd.from !== undefined) return true;
      if(this.p_criteria.ytd.till !== null && this.p_criteria.ytd.till !== undefined) return true;

      if(this.p_criteria.annualisedReturn.from !== null && this.p_criteria.annualisedReturn.from !== undefined) return true;
      if(this.p_criteria.annualisedReturn.till !== null && this.p_criteria.annualisedReturn.till !== undefined) return true;

      if(this.p_criteria.realisedVolatility.from !== null && this.p_criteria.realisedVolatility.from !== undefined) return true;
      if(this.p_criteria.realisedVolatility.till !== null && this.p_criteria.realisedVolatility.till !== undefined) return true;

      if(this.p_criteria.downsideVolatility.from !== null && this.p_criteria.downsideVolatility.from !== undefined) return true;
      if(this.p_criteria.downsideVolatility.till !== null && this.p_criteria.downsideVolatility.till !== undefined) return true;
      
      if(this.p_criteria.maxDrawdown.from !== null && this.p_criteria.maxDrawdown.from !== undefined) return true;
      if(this.p_criteria.maxDrawdown.till !== null && this.p_criteria.maxDrawdown.till !== undefined) return true;

      if(this.p_criteria.winningMonths.from !== null && this.p_criteria.winningMonths.from !== undefined) return true;
      if(this.p_criteria.winningMonths.till !== null && this.p_criteria.winningMonths.till !== undefined) return true;

      if(this.p_criteria.sharpeRatio.from !== null && this.p_criteria.sharpeRatio.from !== undefined) return true;
      if(this.p_criteria.sharpeRatio.till !== null && this.p_criteria.sharpeRatio.till !== undefined) return true;

      if(this.p_criteria.sortinoRatio.from !== null && this.p_criteria.sortinoRatio.from !== undefined) return true;
      if(this.p_criteria.sortinoRatio.till !== null && this.p_criteria.sortinoRatio.till !== undefined) return true;

      if(this.p_criteria.calmarRatio.from !== null && this.p_criteria.calmarRatio.from !== undefined) return true;
      if(this.p_criteria.calmarRatio.till !== null && this.p_criteria.calmarRatio.till !== undefined) return true;


      return false;
    }
  },
  methods:{
    
    applyQuickFilter(name) {
      //debugger; // eslint-disable-line no-debugger
      let onlyReset = false;
      if (name === 'crypto' && this.isCryptoQuickFilterEnabled) {
        onlyReset = true;
      }
      if(name === 'globalETFs' && this.isGlobalETFsQuickFilterEnabled) {
        onlyReset = true;
      }
      if(name === 'thematic' && this.isThematicQuickFilterEnabled) {
        onlyReset = true;
      }
      if (name === 'alpha' && this.isAlphaQuickFilterEnabled) {
        onlyReset = true;
      }
      if (name === 'commods' && this.isCommodsQuickFilterEnabled) {
        onlyReset = true;
      }
      if (name === 'esg' && this.isESGQuickFilterEnabled) {
        onlyReset = true;
      }
      
      this.p_criteria = this.defaultCriteria();

  
      if(!onlyReset) {
        if(name === 'crypto') {
          const cryptoCurrencyAsset = this.values.assetClass.find(x => x.text === 'Crypto Currency');
          const instrumentTypeCrypto = this.values.instrumentType.find(x => x.text === 'Crypto');
          this.p_criteria.assetClass = [... this.p_criteria.assetClass.filter(x => x !== cryptoCurrencyAsset.value), cryptoCurrencyAsset.value];
          this.p_criteria.instrumentType = [... this.p_criteria.instrumentType.filter(x => x !== instrumentTypeCrypto.value), instrumentTypeCrypto.value];
        } 
        else if(name === 'commods') {
          const assetClass1 = this.values.assetClass.find(x => x.text === 'Commodity');
          const assetClass2 = this.values.assetClass.find(x => x.text === 'Futures Commodities');
          this.p_criteria.assetClass = [... this.p_criteria.assetClass.filter(x => x !== assetClass1.value && x !== assetClass2.value), assetClass1.value, assetClass2.value];
        }
        else if(name === 'esg') {
          const indexType1 = this.values.indexType.find(x => x.text === 'ESG');
          const indexType2 = this.values.indexType.find(x => x.text === 'Market Cap');
          const indexSubType1 = (this.values.indexSubType[indexType2.value] || []).find(x => x.text === 'ESG');
     
          this.p_criteria.indexType = [
           ...this.p_criteria.indexType.filter(x => x !== indexType1.value && x !== indexType2.value), 
           indexType1.value,
           indexType2.value];

          this.p_criteria.indexSubType = [
           ...this.p_criteria.indexSubType.filter(x => x !== (indexType2.value + '/' +  indexSubType1.value)), 
           (indexType2.value + '/' +  indexSubType1.value)];
        }
        else  if(name === 'globalETFs') {

          const market = this.values.market.find(x => x.text === 'Global');
          const indexType = this.values.indexType.find(x => x.text === 'ETF');
          this.p_criteria.indexType = [... this.p_criteria.indexType.filter(x => x !== indexType.value), indexType.value];
          this.p_criteria.market = [... this.p_criteria.market.filter(x => x !== market.value), market.value];
        } else  if(name === 'thematic') {

          const indexType2 = this.values.indexType.find(x => x.text === 'Thematic');
          this.p_criteria.indexType = [... this.p_criteria.indexType.filter(x => x !== indexType2.value), indexType2.value];
        } else  if(name === 'alpha') {
        
          const indexType1 = this.values.indexType.find(x => x.text === 'ESG');
          const indexType2 = this.values.indexType.find(x => x.text === 'Risk Parity');
          const indexType3 = this.values.indexType.find(x => x.text === 'Risk Premia');
          const indexType4 = this.values.indexType.find(x => x.text === 'Statistical Arbitrage');
          const indexType5 = this.values.indexType.find(x => x.text === 'Trend Following');
          const instrumentType1 = this.values.instrumentType.find(x => x.text === 'Active Index');
          // const instrumentType2 = this.values.instrumentType.find(x => x.text === 'Overlay');
          this.p_criteria.indexType = [
              ... this.p_criteria.indexType.filter(x => x !== indexType1.value 
              && x !== indexType2.value
              && x !== indexType3.value
              && x !== indexType4.value
              && x !== indexType5.value), 
              indexType1.value, 
              indexType2.value,
              indexType3.value,
              indexType4.value,
              indexType5.value];
              
            this.p_criteria.instrumentType = [
              ... this.p_criteria.instrumentType.filter(x => x !== instrumentType1.value ),
              instrumentType1.value        
            ];          
        }
      }
      
      this.save();
      this.applyFilters();
    },
    resetCriteria() {
      this.p_criteria = this.defaultCriteria();
      if(!this.resetButtonNoAction) {
        this.applyFilters();
      }
    },
    save() {
      const json =JSON.stringify(this.p_criteria);
     
      localStorage.setItem('indices_filter_criteria', json);
    },
    async load() {
      if(this.persistent) {

        const allTagsResponse = await http.get('api/index-tags');
        this.allTags = allTagsResponse.data;

        let dataStr = localStorage.getItem('indices_filter_criteria');
        if(dataStr) {
          const data = JSON.parse(dataStr);
          // data.provider = this.values.provider.filter(a => data.provider.find(x => x.value === a.value));
          // data.provider.forEach(x => x.select = true);
           if(data.singleIndices === undefined || data.singleIndices === null){
            data.singleIndices = true;
          }
          if(data.compositeIndices === undefined || data.compositeIndices === null){
            data.compositeIndices = true;
          }

          if(data.onlyMy === undefined || data.onlyMy === null){
            data.onlyMy = false;
          }

          this.p_criteria = data;

          this.$emit('load', this.p_criteria);
          this.isReady = true;
        }else{
         
          this.$emit('load', this.p_criteria);
          this.isReady = true;
        }
      } else {
     
        this.$emit('load', this.p_criteria);
        this.isReady = true;
      }
    },
    applyFilters () {
      this.save();
      this.$emit('apply', this.p_criteria);
    },
    defaultCriteria() {
      return {
        //bookmarksOnly: false,
        keyword: null,
        provider: [],
        market: [],
        rebalanceFrequency: [],
        indexType: [],
        indexSubType: [],
        instrumentType:[
          // 'ActiveIndex',
          // 'PassiveIndex'
        ],
        tags: [],
        assetClass: [],
        mtd:{from:null, till:null},
        ytd:{from:null, till:null},
        annualisedReturn:{from:null, till:null},
        realisedVolatility:{from:null, till:null},
        downsideVolatility: {from:null, till:null},
        maxDrawdown: {from:null, till:null},
        winningMonths: {from:null, till:null},
        sharpeRatio: {from:null, till:null},
        sortinoRatio: {from:null, till:null},
        calmarRatio: {from:null, till:null},
        singleIndices: true,
        compositeIndices: true,
        onlyMy: false,
        portfolioSolution:[],
      };
    },
    textSearchOnEnter(){
      if (this.p_criteria.keyword){
        this.applyFilters();
      }
    }  
  }
};
</script>

<template>

    <div class=""  v-if="isReady" :class="{'card cardc8':!noCard}">
      <div class="" :class="{'card-content':!noCard}">
        <div class=" bg-transparent d-flex justify-content-between"  :class="{'card-header':!noCard}"  v-if="!noCard">

          <h5>Filters</h5>
          <!-- <span >
            <b-form-checkbox v-model="p_criteria.bookmarksOnly" switch class="mr-1 " style="display:inline-block;">
          
            </b-form-checkbox>Bookmarks only
          </span> -->
        </div>
        <div class="card-body">
          <simplebar style="max-height:100%;">
            <div class="row mb-4 mr-2 ml-2">

              <div class="col-md-6">
                <b-form-group>
                  <button 
                    class="btn btn-block"
                    @click="applyQuickFilter('alpha')"
                    :class="{'btn-outline-secondary': !isAlphaQuickFilterEnabled, 'btn-primary': isAlphaQuickFilterEnabled}"
                    title="Select a shortlist of active indices that seek excess risk adjusted returns.">
                      <i class="mdi mdi-filter-outline mr-2" v-if="!isAlphaQuickFilterEnabled"/>
                      <i class="mdi mdi-filter mr-2" v-if="isAlphaQuickFilterEnabled"/>  
                     Alpha
                  </button>
                </b-form-group>
              </div>

               <div class="col-md-6">
                <b-form-group>
                  <button 
                    class="btn btn-block"
                    :class="{'btn-outline-secondary': !isThematicQuickFilterEnabled, 'btn-primary': isThematicQuickFilterEnabled}"
                     @click="applyQuickFilter('thematic')"
                     title="Explore indices that focus on a theme, such as water security or ESG."
                  >
                    <i class="mdi mdi-filter-outline mr-2" v-if="!isThematicQuickFilterEnabled"/>
                    <i class="mdi mdi-filter mr-2" v-if="isThematicQuickFilterEnabled"/>
                    Thematic
                  </button>
                </b-form-group>
              </div>

              <div class="col-md-6">
                <b-form-group>
                  <button 
                    class="btn  btn-block" 
                    :class="{'btn-outline-secondary': !isGlobalETFsQuickFilterEnabled, 'btn-primary': isGlobalETFsQuickFilterEnabled}"
                    title="Global coverage ETFs not tied to a particular region, with underlying investments in equities, fixed income or alternatives."
                    @click="applyQuickFilter('globalETFs')"
                  >
                    <i class="mdi mdi-filter-outline mr-2" v-if="!isGlobalETFsQuickFilterEnabled"/>
                    <i class="mdi mdi-filter mr-2" v-if="isGlobalETFsQuickFilterEnabled"/>
                    Global ETFs
                  </button>
                </b-form-group>
              </div>

              <div class="col-md-6">
                <b-form-group>
                  <button 
                    class="btn  btn-block" 
                    :class="{'btn-outline-secondary': !isESGQuickFilterEnabled, 'btn-primary': isESGQuickFilterEnabled}"
                    title="Selecting on environmental, social, and governance criteria is in high demand with the potential for the portfolio to perform."
                    @click="applyQuickFilter('esg')"
                  >
                    <i class="mdi mdi-filter-outline mr-2" v-if="!isESGQuickFilterEnabled"/>
                    <i class="mdi mdi-filter mr-2" v-if="isESGQuickFilterEnabled"/>
                    ESG
                  </button>
                </b-form-group>
              </div>

              <div class="col-md-6">
                <b-form-group>
                  <button 
                    class="btn  btn-block" 
                    :class="{'btn-outline-secondary': !isCommodsQuickFilterEnabled, 'btn-primary': isCommodsQuickFilterEnabled}"
                    title="Commodities provide a diversified alternatives to equities and fixed income, either through ETFs or through a systematic process."
                    @click="applyQuickFilter('commods')"
                  >
                    <i class="mdi mdi-filter-outline mr-2" v-if="!isCommodsQuickFilterEnabled"/>
                    <i class="mdi mdi-filter mr-2" v-if="isCommodsQuickFilterEnabled"/>
                    Commods
                  </button>
                </b-form-group>
              </div>
              

              <div class="col-md-6">
                <b-form-group>
                  <button 
                    class="btn  btn-block" 
                    :class="{'btn-outline-secondary': !isCryptoQuickFilterEnabled, 'btn-primary': isCryptoQuickFilterEnabled}"
                    title="Select crypto currencies available on the C8 platform, excluding our proprietary overlays."
                    @click="applyQuickFilter('crypto')"
                  >
                    <i class="mdi mdi-filter-outline mr-2" v-if="!isCryptoQuickFilterEnabled"/>
                    <i class="mdi mdi-filter mr-2" v-if="isCryptoQuickFilterEnabled"/>
                    Crypto
                  </button>
                </b-form-group>
              </div>
           
            </div> 
           
            <b-form-group
              class="mt-2" 
              label-for="type"
            >
              <div class="search-box">
                <div class="position-relative">
                  <input 
                    type="text" 
                    class="form-control rounded " 
                    v-model="p_criteria.keyword" 
                    v-on:keyup.enter="textSearchOnEnter" 
                    :placeholder="$t('pages.indices.keyword')"  
                    id="keywords"
                  />
                  <i class="mdi mdi-magnify search-icon"></i>
                </div>
              </div>
            </b-form-group>

            <div class="form-group">
              <multiselect 
                v-model="p_criteria.tags" 
                placeholder="Tags"
                :options="allTags" 
                :taggable="true"
                :multiple="true">
              </multiselect>
            </div>
          
            <!-- <b-form-group
              class="mt-2 mr-4"
              label-for="type"
            >
            <b-form-checkbox
              v-model="p_criteria.onlyMy"
              class="d-inline mr-2"
            > 
              My
            </b-form-checkbox>
              <b-form-checkbox
                v-model="p_criteria.singleIndices"
                class="d-inline mr-2"
              > 
                Single
              </b-form-checkbox>
              <b-form-checkbox
                v-model="p_criteria.compositeIndices"
                class="d-inline mr-2"
              > 
                Combined
              </b-form-checkbox>
              
            </b-form-group> -->
          
            
            <div id="accordion" class="custom-accordion categories-accordion">

              <div class="categories-group-card">
                <a v-b-toggle.instrumentType class="categories-group-list">
                  <i class="mdi mdi-hammer-wrench font-size-16 align-middle mr-2"></i> 
                  <span class="mr-2">Instrument type</span>
                  <small class="text-primary" v-if="p_criteria.instrumentType.length === 1">[ Selected 1 item]</small>
                  <small class="text-primary" v-if="p_criteria.instrumentType.length > 1">[Selected {{p_criteria.instrumentType.length}} items]</small>
                  <i class="mdi mdi-minus float-right accor-plus-icon"></i>
                </a>

                <b-collapse visible id="instrumentType" data-parent="#accordion" accordion="instrumentType">
                  <div class="pl-3 pb-2">
                    <b-form-checkbox-group stacked 
                      v-model="p_criteria.instrumentType"
                    >
                      <b-form-checkbox :value="i.value" v-for="i in values.instrumentType" :key="i.value">{{i.text}}</b-form-checkbox>
                    </b-form-checkbox-group>
                  </div>
                </b-collapse>
              </div>

              <div class="categories-group-card">
                <a v-b-toggle.assetClass class="categories-group-list">
                  <i class="mdi mdi-hexagon-multiple-outline font-size-16 align-middle mr-2"></i> 
                  <span class="mr-2">Asset class</span>
                  <small class="text-primary" v-if="p_criteria.assetClass.length === 1">[ Selected 1 item]</small>
                  <small class="text-primary" v-if="p_criteria.assetClass.length > 1">[Selected {{p_criteria.assetClass.length}} items]</small>
                  <i class="mdi mdi-minus float-right accor-plus-icon"></i>
                </a>

                <b-collapse id="assetClass" data-parent="#accordion" accordion="assetClass">
                  <div class="pl-3 pb-2">
                      <b-form-checkbox-group stacked 
                      v-model="p_criteria.assetClass"
                    >
                      <b-form-checkbox :value="i.value" v-for="i in values.assetClass" :key="i.value">{{i.text}}</b-form-checkbox>
                    </b-form-checkbox-group>
                    
                  </div>
                </b-collapse>
              </div>

              <div class="categories-group-card">
                <a v-b-toggle.market class="categories-group-list">
                  <i class="mdi mdi-earth font-size-16 align-middle mr-2"></i> 
                    <span class="mr-2">Region</span>
                    <small class="text-primary" v-if="p_criteria.market.length === 1">[ Selected 1 item]</small>
                    <small class="text-primary" v-if="p_criteria.market.length > 1">[Selected {{p_criteria.market.length}} items]</small>
                  <i class="mdi mdi-minus float-right accor-plus-icon"></i>
                </a>

                <b-collapse id="market" data-parent="#accordion" accordion="market">
                  <div class="pl-3 pb-2">
                    <b-form-checkbox-group stacked 
                      v-model="p_criteria.market"
                    >
                      <b-form-checkbox :value="i.value" v-for="i in values.market" :key="i.value">{{i.text}}</b-form-checkbox>
                    </b-form-checkbox-group>
                  </div>
                </b-collapse>
              </div>

              <div class="categories-group-card">
                <a v-b-toggle.indexType class="categories-group-list">
                  <i class="mdi mdi-format-list-bulleted-type font-size-16 align-middle mr-2"></i> 
                  <span class="mr-2">Index type</span>
                  <small class="text-primary" v-if="p_criteria.indexType.length === 1">[ Selected 1 item]</small>
                  <small class="text-primary" v-if="p_criteria.indexType.length > 1">[Selected {{p_criteria.indexType.length}} items]</small>
                  <i class="mdi mdi-minus float-right accor-plus-icon"></i>
                </a>

                <b-collapse id="indexType" data-parent="#accordion" accordion="indexType">
                  <div class="pl-3 pb-2">
                    <b-form-checkbox-group stacked 
                      v-model="p_criteria.indexType"
                    >
                      <template  v-for="i in values.indexType">
                      
                        <b-form-checkbox :value="i.value" :key="i.value">{{i.text}}</b-form-checkbox>
                        <div class="ml-4 small " :key="i.value + 'a'" v-if="(values.indexSubType[i.value] || []).length > 0 && p_criteria.indexType.find(a => a === i.value)">
                          <b-form-checkbox-group stacked 
                              v-model="p_criteria.indexSubType"
                            >
                              <b-form-checkbox :value="i.value + '/' +  s.value" v-for="s in values.indexSubType[i.value]" :key="i.value + '/' +  s.value">{{s.text}}</b-form-checkbox>
                            </b-form-checkbox-group>
                        </div>
                      </template>
                    </b-form-checkbox-group>
                  </div>
                </b-collapse>
              </div>

              <div class="categories-group-card">
                <a v-b-toggle.provider class="categories-group-list">
                  <i class="mdi mdi-bank-outline font-size-16 align-middle mr-2"></i> 
                    <span class="mr-2">Provider</span>
                    <small class="text-primary" v-if="p_criteria.provider.length === 1">[ Selected 1 provider]</small>
                    <small class="text-primary" v-if="p_criteria.provider.length > 1">[Selected {{p_criteria.provider.length}} providers]</small>
                  <i class="mdi mdi-minus float-right accor-plus-icon"></i>
                </a>

                <b-collapse id="provider" data-parent="#accordion" accordion="provider">
                  <div class="pl-3 pb-2">
                   
                      <b-form-checkbox-group stacked 
                        v-model="p_criteria.provider"
                      >
                        <b-form-checkbox :value="i.value" v-for="i in providers" :key="i.value">{{i.text}}</b-form-checkbox>
                      </b-form-checkbox-group>
                  
                    
                  </div>
                </b-collapse>
              </div>

              <div class="categories-group-card">
                <a v-b-toggle.statistics class="categories-group-list">
                  <i class="mdi mdi-filter-variant font-size-16 align-middle mr-2"></i> 
                  <span class="mr-2">{{$t('tables.indices.statistics')}}</span>
                  <small class="text-primary" v-if="isStatFiltered">[Filter enabled]</small>
                  <i class="mdi mdi-minus float-right accor-plus-icon"></i>
                </a>

                <b-collapse id="statistics" data-parent="#accordion" accordion="statistics" style="position:relative;">
                    <div class="pl-3 pb-2 pr-3">
                    <b-form-group
                      class="mt-2"
                      :label="$t('tables.indices.columns.mtd')"
                      label-for="type"
                    >
                      <div class="d-flex flex-row">
                        <div class="flex-fill pr-1">
                          <input class="form-control" type="text" v-model.number="p_criteria.mtd.from"/>
                        </div>
                        <div class="flex-fill pl-1">
                          <input class="form-control" type="text" v-model.number="p_criteria.mtd.till"/>
                        </div>
                      </div>
                    </b-form-group>
                    <b-form-group
                      class="mt-2"
                      :label="$t('tables.indices.columns.ytd')"
                      label-for="type"
                    >
                      <div class="d-flex flex-row">
                        <div class="flex-fill pr-1">
                          <input class="form-control" type="text" v-model.number="p_criteria.ytd.from"/>
                        </div>
                        <div class="flex-fill pl-1">
                          <input class="form-control" type="text" v-model.number="p_criteria.ytd.till"/>
                        </div>
                      </div>
                    </b-form-group>
                    <b-form-group
                      class="mt-2"
                      :label="$t('tables.indices.columns.annualisedReturn')"
                      label-for="type"
                    >
                      <div class="d-flex flex-row">
                        <div class="flex-fill pr-1">
                          <input class="form-control" type="text" v-model.number="p_criteria.annualisedReturn.from"/>
                        </div>
                        <div class="flex-fill pl-1">
                          <input class="form-control" type="text" v-model.number="p_criteria.annualisedReturn.till"/>
                        </div>
                      </div>
                    </b-form-group>
                    <b-form-group
                      class="mt-2"
                      :label="$t('tables.indices.columns.realisedVolatility')"
                      label-for="type"
                    >
                      <div class="d-flex flex-row">
                        <div class="flex-fill pr-1">
                          <input class="form-control" type="text" v-model.number="p_criteria.realisedVolatility.from"/>
                        </div>
                        <div class="flex-fill pl-1">
                          <input class="form-control" type="text" v-model.number="p_criteria.realisedVolatility.till"/>
                        </div>
                      </div>
                    </b-form-group>
                    <b-form-group
                      class="mt-2"
                      :label="$t('tables.indices.columns.downsideVolatility')"
                      label-for="type"
                    >
                      <div class="d-flex flex-row">
                        <div class="flex-fill pr-1">
                          <input class="form-control" type="text" v-model.number="p_criteria.downsideVolatility.from"/>
                        </div>
                        <div class="flex-fill pl-1">
                          <input class="form-control" type="text" v-model.number="p_criteria.downsideVolatility.till"/>
                        </div>
                      </div>
                    </b-form-group>
                    <b-form-group
                      class="mt-2"
                      :label="$t('tables.indices.columns.maxDrawdown')"
                      label-for="type"
                    >
                      <div class="d-flex flex-row">
                        <div class="flex-fill pr-1">
                          <input class="form-control" type="text" v-model.number="p_criteria.maxDrawdown.from"/>
                        </div>
                        <div class="flex-fill pl-1">
                          <input class="form-control" type="text" v-model.number="p_criteria.maxDrawdown.till"/>
                        </div>
                      </div>
                    </b-form-group>
                    <b-form-group
                      class="mt-2"
                      :label="$t('tables.indices.columns.winningMonths')"
                      label-for="type"
                    >
                      <div class="d-flex flex-row">
                        <div class="flex-fill pr-1">
                          <input class="form-control" type="text" v-model.number="p_criteria.winningMonths.from"/>
                        </div>
                        <div class="flex-fill pl-1">
                          <input class="form-control" type="text" v-model.number="p_criteria.winningMonths.till"/>
                        </div>
                      </div>
                    </b-form-group>
                    <b-form-group
                      class="mt-2"
                      :label="$t('tables.indices.columns.sharpeRatio')"
                      label-for="type"
                    >
                      <div class="d-flex flex-row">
                        <div class="flex-fill pr-1">
                          <input class="form-control" type="text" v-model.number="p_criteria.sharpeRatio.from"/>
                        </div>
                        <div class="flex-fill pl-1">
                          <input class="form-control" type="text" v-model.number="p_criteria.sharpeRatio.till"/>
                        </div>
                      </div>
                    </b-form-group>
                    <b-form-group
                      class="mt-2"
                      :label="$t('tables.indices.columns.sortinoRatio')"
                      label-for="type"
                    >
                      <div class="d-flex flex-row">
                        <div class="flex-fill pr-1">
                          <input class="form-control" type="text" v-model.number="p_criteria.sortinoRatio.from"/>
                        </div>
                        <div class="flex-fill pl-1">
                          <input class="form-control" type="text" v-model.number="p_criteria.sortinoRatio.till"/>
                        </div>
                      </div>
                    </b-form-group>
                    <b-form-group
                      class="mt-2"
                      :label="$t('tables.indices.columns.calmarRatio')"
                      label-for="type"
                    >
                      <div class="d-flex flex-row">
                        <div class="flex-fill pr-1">
                          <input class="form-control" type="text" v-model.number="p_criteria.calmarRatio.from"/>
                        </div>
                        <div class="flex-fill pl-1">
                          <input class="form-control" type="text" v-model.number="p_criteria.calmarRatio.till"/>
                        </div>
                      </div>
                    </b-form-group>
                  </div>
                </b-collapse>
              </div>
              
              <div class="categories-group-card">
                <a v-b-toggle.rebalanceFrequency class="categories-group-list">
                  <i class="mdi mdi-scale-balance font-size-16 align-middle mr-2"></i> 
                  <span class="mr-2">Rebalance frequency</span>
                  <small class="text-primary" v-if="p_criteria.rebalanceFrequency.length === 1">[ Selected 1 item]</small>
                  <small class="text-primary" v-if="p_criteria.rebalanceFrequency.length > 1">[Selected {{p_criteria.rebalanceFrequency.length}} items]</small>
                  <i class="mdi mdi-minus float-right accor-plus-icon"></i>
                </a>

                <b-collapse id="rebalanceFrequency" data-parent="#accordion" accordion="rebalanceFrequency">
                  <div class="pl-3 pb-2">
                      <b-form-checkbox-group stacked 
                      v-model="p_criteria.rebalanceFrequency"
                    >
                      <b-form-checkbox :value="i.value" v-for="i in values.rebalanceFrequency" :key="i.value">{{i.text}}</b-form-checkbox>
                    </b-form-checkbox-group>
                  </div>
                </b-collapse>
              </div>

              <div class="categories-group-card">
                <a v-b-toggle.Optimization class="categories-group-list">
                  <i class="fas fa-tachometer-alt font-size-16 align-middle mr-2"></i> 

                  <span class="mr-2">Portfolio solution</span>
                  <small class="text-primary" v-if="p_criteria.portfolioSolution && p_criteria.portfolioSolution.length === 1">[ Selected 1 item]</small>
                  <small class="text-primary" v-if="p_criteria.portfolioSolution && p_criteria.portfolioSolution.length > 1">[Selected {{p_criteria.portfolioSolution.length}} items]</small>

                  <i class="mdi mdi-minus float-right accor-plus-icon"></i>
                </a>

                <b-collapse id="Optimization" data-parent="#accordion" accordion="Optimization">
                  <div class="pl-3 pb-2">
                    <b-form-checkbox-group stacked 
                      v-model="p_criteria.portfolioSolution"
                    >
                      <b-form-checkbox :value="i.value" v-for="i in values.portfolioSolution" :key="i.value">{{i.text}}</b-form-checkbox>
                    </b-form-checkbox-group>
                  </div>
                </b-collapse>
              </div>

            <div class="categories-group-card">
                <a v-b-toggle.Miscellaneous class="categories-group-list">
                  <i class="fas fa-peace font-size-16 align-middle mr-2"></i> 
                    <span class="mr-2">Single or Composite </span>
                  <small class="text-primary" v-if="p_criteria.singleIndices !== p_criteria.compositeIndices"><br/>[Filter enabled]</small>
                  
                  <i class="mdi mdi-minus float-right accor-plus-icon"></i>
                </a> 

                 <b-collapse id="Miscellaneous" data-parent="#accordion" accordion="Miscellaneous">
                  <div class="pl-3 pb-2">
                    <b-form-group
                      class="mt-2 mr-4"
                      label-for="type"
                    >
                      <b-form-checkbox
                          v-model="p_criteria.singleIndices"
                        > 
                        Single Indices
                      </b-form-checkbox>
                      <b-form-checkbox
                          v-model="p_criteria.compositeIndices"
                        > 
                        Composite Indices
                      </b-form-checkbox>
                      
                    </b-form-group>
                  </div>
                </b-collapse>
               </div> 
            </div>
          </simplebar>
        </div>
        <div class="buttons">
          <button class=" mt-2 btn btn-c8 btn-block" @click="applyFilters">
            <span class="btn-content">
              {{applyButtonTitle}}
            </span>
          </button>
          <button type="button" class=" mt-2 float-right btn btn-sm btn-link" @click="resetCriteria">
            Reset filters
          </button>
        </div>
      </div>
    </div>
    
</template>